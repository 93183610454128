import { css, DocumentCard, Icon, IPersonaProps, Link, Persona, PersonaSize, PrimaryButton, Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';
import { User } from '@microsoft/microsoft-graph-types';
import * as React from 'react';

import { useGetEntity, useGetPhoto, useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { TelemetryEvent } from '../../../models';
import { IAccessPackageRecommendations } from '../../../models/ELM/IAccessPackageRecommendations';
import { isEmptyOrUndefined, LocaleKeys } from '../../../shared';
import { getEntitlementUrl } from '../../../shared/getEntitlementUrl';
import { TruncatedText } from '../../Shared';
const styles = require('./RecommendationCarousel.scoped.scss');

const shimmerButton = [{ type: ShimmerElementType.line, height: 32, width: 297 }];
const shimmerIsThisUseful = [{ type: ShimmerElementType.line, height: 16, width: 150 }];
const yesFeedback = "Yes";
const noFeedback = "No";

const getCardBodyShimmer = (): JSX.Element => {
  return (
    <ShimmerElementsGroup
      flexWrap
      shimmerElements={[
        { type: ShimmerElementType.line, height: 35, width: "100%" },
        { type: ShimmerElementType.gap, height: 1, width: "100%" },
        { type: ShimmerElementType.line, height: 28, width: "100%" },
        { type: ShimmerElementType.line, height: 38, width: "100%" },
        { type: ShimmerElementType.line, height: 24, width: "100%" }
      ]}
    />
  );
};

interface RecommendationCardProps {
  accessPackageId: string;
  details: IAccessPackageRecommendations;
  coworkers?: readonly User[];
}

function getPrimaryCoworker(coworkers: readonly User[] | undefined, primaryUserObjectId?: string): User | null {
  if (!coworkers || coworkers.length === 0) {
    return null;
  }

  if (primaryUserObjectId) {
    const filteredCoworkers = coworkers?.filter((coworker) => coworker?.id === primaryUserObjectId);
    if (filteredCoworkers?.length > 0) {
      return filteredCoworkers[0];
    }
  }

  return coworkers?.[Math.floor(Math.random() * coworkers.length)];
}

export const RecommendationCard = ({ coworkers, accessPackageId, details }: RecommendationCardProps): JSX.Element => {
  const { getEntitlement, entitlementsById, entityLoadingList, isLoadingEntity, errorHasOccurred: errorGettingEntities } = useGetEntity();
  const primaryCoworker = getPrimaryCoworker(coworkers, details?.primaryUserObjectId);
  const { usersPhoto, getPhoto } = useGetPhoto();
  React.useEffect(() => {
    if (primaryCoworker?.id && !usersPhoto.has(primaryCoworker.id)) {
      getPhoto({ userObjectId: primaryCoworker?.id })
    }
  }, [primaryCoworker, usersPhoto, getPhoto])
  let personaProps: IPersonaProps = {
    size: PersonaSize.size40,
    hidePersonaDetails: true
  };
  if (primaryCoworker?.id && primaryCoworker?.displayName) {
    const userPhoto = usersPhoto.get(primaryCoworker?.id);
    personaProps = {
      ...personaProps,
      text: primaryCoworker?.displayName,
      imageUrl: userPhoto === null ? undefined : userPhoto,
    }
  }

  const accessPackage = entitlementsById.get(accessPackageId);
  const isLoadingAccessPackage = entityLoadingList[accessPackageId] ? entityLoadingList[accessPackageId] : false;
  React.useEffect(() => {
    if (accessPackageId && !isLoadingAccessPackage && !accessPackage && !isLoadingEntity && !errorGettingEntities) {
      getEntitlement(accessPackageId)
    }
  }, [accessPackageId, accessPackage, isLoadingAccessPackage, getEntitlement, isLoadingEntity, entitlementsById, entityLoadingList, errorGettingEntities])

  const t = useTranslation();
  const cardHeader = details.relatedUserCount ? t(LocaleKeys.peopleCount, { count: details.relatedUserCount - 1 }) : t(LocaleKeys.peopleSingular);

  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const [feedbackGiven, setFeedbackGiven] = React.useState(() => {
    return localStorage.getItem(details.id) !== null;
  });

  const sendFeedback = (value: string) => () => {
    setFeedbackGiven(true);
    localStorage.setItem(details.id, value);
    reportCustomEventWithMetadata(TelemetryEvent.Recommendation_Feedback, { accessPackageId, feedback: value })
  };

  const sendTelemetry = (): void => {
    reportCustomEventWithMetadata(TelemetryEvent.Recommendation_Clicked, { accessPackageId })
  }

  const isDataLoaded = accessPackage !== undefined;
  return (
    <DocumentCard className={css(styles.card)}>
      <div className={css(styles.cardBody)}>
        <Shimmer styles={{ shimmerWrapper: css(styles.shimmerBody) }} customElementsGroup={getCardBodyShimmer()} isDataLoaded={primaryCoworker?.id !== undefined} >
          <div className={css(styles.recommendationTitle)}>
            <Persona {...personaProps} />
            <p><span className={styles.bold}>{primaryCoworker?.displayName}</span> {cardHeader}</p>
          </div>
          <p className={css(styles.displayName)}>{details.accessPackage?.displayName}</p>
          <Shimmer isDataLoaded={!isEmptyOrUndefined(accessPackage?.description)} styles={{ shimmerWrapper: css(styles.shimmerDescription) }} >
            <TruncatedText><p className={css(styles.description)}>{accessPackage?.description}</p></TruncatedText>
          </Shimmer>
        </Shimmer>
      </div>
      <div className={css(styles.cardFooter)}>
        <div className={css(styles.buttonWrapper)}>
          <Shimmer isDataLoaded={isDataLoaded} shimmerElements={shimmerButton} >
            <PrimaryButton onClick={sendTelemetry} href={getEntitlementUrl(accessPackageId)}>{t(LocaleKeys.request, { context: 'capitalize' })}</PrimaryButton>
          </Shimmer>
        </div>
        <Shimmer isDataLoaded={isDataLoaded} shimmerElements={shimmerIsThisUseful} >
          <div className={css(styles.isThisUseful)}>
            <Icon iconName="Info" />
            {feedbackGiven ?
              t(LocaleKeys.thanks)
              :
              <>
                {t(LocaleKeys.isThisUseful)}
                <Link onClick={sendFeedback(yesFeedback)}>{t(LocaleKeys.yes)}</Link>
                <Link onClick={sendFeedback(noFeedback)}>{t(LocaleKeys.no)}</Link>
              </>
            }
          </div>
        </Shimmer>
      </div>
    </DocumentCard >
  );
}