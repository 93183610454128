import { ColorClassNames, FontClassNames } from '@fluentui/react';
import { css, Icon, PrimaryButton } from '@fluentui/react';
import * as React from 'react';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface INoEntitiesProps {
  iconName: string;
  noRowMessage: string;
  showButton: boolean;
  buttonText?: string;
  onButtonClick?(): void;
}

export class NoEntities extends React.Component<INoEntitiesProps, {}> {
  public render(): JSX.Element {
    const { showButton, iconName, noRowMessage, onButtonClick } = this.props;

    return (
      <div
        className={css(
          myAccessStyles.alignCenter,
          myAccessStyles.marginTopXLarge
        )}
      >
        <Icon
          iconName={iconName}
          className={css(
            myAccessStyles.largeIcon,
            ColorClassNames.neutralPrimary
          )}
        />
        <div>
          <div
            className={css(
              FontClassNames.xLarge,
              myAccessStyles.marginTopMedium,
              myAccessStyles.marginBottomSmall,
              ColorClassNames.neutralPrimary
            )}
          >
            {noRowMessage}
          </div>
          {showButton ? (
            <div
              className={css(
                FontClassNames.medium,
                ColorClassNames.neutralPrimary
              )}
            >
              <PrimaryButton
                text={this.props.buttonText!}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => onButtonClick!()}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
