import { ILoading, LoadingState } from '@microsoft/portal-app/lib/models/ILoading';

import { IEntitlementState } from '../../models';
import { CycleActivationStatus } from '../../models/RAM/IActivationStatusPayload';
import { FeatureMap, features, LocaleKeys } from '../../shared';

export const defaultState: IEntitlementState = {
  entitlements: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'displayName',
      isSortedDescending: false,
      fieldName: '',
      name: '',
      minWidth: 0
    },
    entitiesById: new Map(),
    isLoading: false
  },
  policies: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    entitiesById: new Map(),
    isLoading: false
  },
  policyAssignments: new Map<string, string[]>(),
  policyTargetLoadingMap: new Map<string, ILoading>(),
  catalogs: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    entitiesById: new Map(),
    isLoading: false
  },
  grantRequests: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'createdDateTime',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  validGrants: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'accessPackage/displayName',
      isSortedDescending: false,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  expiredGrants: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'accessPackage/displayName',
      isSortedDescending: false,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  pendingApprovalGrantRequests: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'endDateTime',
      isSortedDescending: false,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  completedGrantRequests: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'createdDateTime',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  pendingRaeRequests: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'endDateTime',
      isSortedDescending: false,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  expiredRaeRequests: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    entitiesById: new Map(),
    isLoading: false
  },
  completedRaeRequests: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'createdDateTime',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  accessReviews: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'reviewEndDate',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  accessPackageReviews: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'reviewEndDate',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  aadRolesReviews: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'reviewEndDate',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  rbacReviews: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'reviewEndDate',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  byodReviews: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'reviewEndDate',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  userAccessReviews: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'reviewEndDate',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  accessReviewDecisions: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'userDisplayName',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  secondaryDecisions: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'userDisplayName',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  supervisorCentricReviewDecisions: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'userDisplayName',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  supervisorCentricReviewDecisionsResources: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    sortedColumn: {
      key: 'userDisplayName',
      isSortedDescending: true,
      fieldName: '',
      minWidth: 0,
      name: ''
    },
    entitiesById: new Map(),
    isLoading: false
  },
  decisionsCriteria: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    entitiesById: new Map(),
    isLoading: false
  },
  entitlementsForCommand: [],
  showingAddGrantRequest: false,
  showingGrantFilter: false,
  showingGrantRequestFilter: false,
  showingCopyLink: false,
  showingConfirmDialog: false,
  showingPendingRaeRequestDetails: false,
  showingMoreGrantRequestDetails: false,
  showingReviewDetails: false,
  showingUserCentricPanel: false,
  showingReviewDecisionsFilter: false,
  showingReviewDecisionsDialog: false,
  showingDecisionDetails: false,
  showingBulkDecisionDialog: false,
  showingResetDecisions: false,
  showingAcceptRecommendations: false,
  searchTerm: '',
  searchContext: undefined,
  searchPlaceholderText: { key: LocaleKeys.searchPackages },
  searchBoxVisible: true,
  filterItems: [],
  isEntityDetailLoading: false,
  entityLoadingList: {},
  notificationsLimit: 20,
  submitting: false,
  errorHasOccurred: false,
  errorCode: 0,
  validating: false,
  validationErrors: [],
  isTenantWhitelisted: true,
  approvalsForSelectedRequest: [],
  showFiltersIcon: false,
  bulkDecisionType: 'Approve',
  pageCounts: {},
  features: {
    isLoaded: false,
    isEnabled: Object.keys(features).reduce((accum, x) => {
      accum[x] = false;
      return accum;
    }, {}) as FeatureMap
  },
  showMessageBar: false,
  hasOfficeLicenses: false,
  navBarData: undefined,
  tenantBrandingUrl: '',
  enabledApps: { isLoading: true, value: {} },
  currentOrgId: '',
  currentOrgDisplayName: '',
  organizations: { isLoading: true, value: [] },
  userImageUrl: '',
  hasError: false,
  hasP2License: false,
  routes: [],
  directReports: { isLoading: false, loadingState: LoadingState.notLoaded, value: [] },
  coworkers: { isLoading: false, loadingState: LoadingState.notLoaded, value: [] },
  // RAM
  virtualMachines: {
    filtered: {
      entities: []
    },
    unfiltered: {
      entities: []
    },
    entitiesById: new Map(),
    isLoading: false
  },
  pendingApprovalList: [],
  activationStatus: { status: CycleActivationStatus.None, roleEndTime: '' },
  pendingApprovalsCount: 0,
  overviewExpiringGrantData: { isLoading: false, isSucceeded: false },
  overviewPendingApprovalData: { isLoading: false, isSucceeded: false },
  overviewGroupReviewData: { isLoading: false, isSucceeded: false },
  overviewAccessPackageReviewData: { isLoading: false, isSucceeded: false },
  recommendations: { isLoading: false, loadingState: LoadingState.notLoaded, value: [] },
  oboEnabledPackages: new Map<string, boolean>(),
  accessReviewsFeatureFlags: { sqlOutage: false, emergencyRAE: '' },
  usersPhoto: new Map<string, string | null>()
};
