import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { NavActions } from '@microsoft/portal-app/lib/Nav/models/Nav';
import { PageBanner } from './PageBanner';

export interface IPageBannerActions {
  expandNav(): void;
}

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch: Dispatch<unknown>): IPageBannerActions => {
  return {
    expandNav: () => {
      dispatch({ type: NavActions.expandNav });
    },
  };
};

export const ConnectedPageBanner = connect(mapStateToProps, mapDispatchToProps)(PageBanner);