import {
  ColorClassNames,
  css,
  FontClassNames,
  Icon,
  Link,
  Pivot,
  PivotItem,
} from '@fluentui/react';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useDispatch, useSelector, useTranslation } from '../../../hooks';
import { useGetEntityCount } from '../../../hooks/useGetEntityCount';
import { EntitlementActions } from '../../../models';
import { IEntity } from '../../../models/ELM/IEntity';
import { IPageData } from '../../../models/IPageData';
import { history } from '../../../redux/configureStore';
import {
  getOneLayerUp,
  getPageMetaDataFromEntities,
  LocaleKeys,
  Routes,
} from '../../../shared';
import { ConnectedPageBanner } from '../../Shared/PageBanner/ConnectedPageBanner';
import { RecommendationCarousel } from '../../Shared/RecommendationCarousel';
import {
  ConnectedActiveGrantList,
  ConnectedEntitlementList,
  ConnectedExpiredGrantList,
} from '../';


const detailsPageStyles = require('@microsoft/portal-app/lib/styling/patterns/DetailsPage.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

enum EntitlementsPagePivots {
  Available = 'available',
  Active = 'active',
  Expired = 'expired',
}

export function EntitlementsPage(): JSX.Element {
  const { getEntitlementsCount, getActiveGrantsCount, getExpiredGrantsCount } = useGetEntityCount();

  useEffect(() => {
    if (location.hash.endsWith(Routes.accessIHaveTab) || location.hash.endsWith(Routes.previousAccessTab)){
      getEntitlementsCount();
    }
    if (location.hash.endsWith(Routes.accessPackages) || location.hash.endsWith(Routes.previousAccessTab)){
      getActiveGrantsCount();
    }
    if (location.hash.endsWith(Routes.accessPackages) || location.hash.endsWith(Routes.accessIHaveTab)){
      getExpiredGrantsCount();
    }
  }, []);

  return (
    <Switch>
      {/* All access packages */}
      <Route
        exact
        path={Routes.accessPackages}
        render={(routeProps) => (
          <MainContent
            pivot={EntitlementsPagePivots.Available}
            isSingleEntity={false}
          >
            <ConnectedEntitlementList {...routeProps} />
          </MainContent>
        )}
      />

      {/* All active access packages */}
      <Route
        exact
        path={Routes.accessIHaveTab}
        render={(routeProps) => (
          <MainContent
            pivot={EntitlementsPagePivots.Active}
            isSingleEntity={false}
          >
            <ConnectedActiveGrantList {...routeProps} />
          </MainContent>
        )}
      />

      {/* All expired access packages */}
      <Route
        exact
        path={Routes.previousAccessTab}
        render={(routeProps) => (
          <MainContent
            pivot={EntitlementsPagePivots.Expired}
            isSingleEntity={false}
          >
            <ConnectedExpiredGrantList {...routeProps} />
          </MainContent>
        )}
      />

      {/* Single active access package */}
      <Route
        exact
        path={`${Routes.accessIHaveTab}/:accessPackageId`}
        render={(routeProps) => (
          <MainContent
            pivot={EntitlementsPagePivots.Active}
            isSingleEntity={true}
          >
            <ConnectedActiveGrantList {...routeProps} />
          </MainContent>
        )}
      />

      {/* Single access package */}
      <Route
        exact
        path={`${Routes.accessPackages}/:accessPackageId`}
        render={(routeProps) => (
          <MainContent
            pivot={EntitlementsPagePivots.Available}
            isSingleEntity={true}
          >
            <ConnectedEntitlementList {...routeProps} />
          </MainContent>
        )}
      />
    </Switch>
  );
}

function MainContent({
  children,
  pivot,
  isSingleEntity
}: {
  children: React.ReactNode;
  pivot: EntitlementsPagePivots;
  isSingleEntity: boolean;
}): JSX.Element {
  const dispatch = useDispatch();
  const t = useTranslation();

  const { entitlements, validGrants, expiredGrants, pageCounts, features } = useSelector(
    (state) => ({
      entitlements: state.app.entitlements,
      validGrants: state.app.validGrants,
      expiredGrants: state.app.expiredGrants,
      searchTerm: state.app.searchTerm,
      pageCounts: state.app.pageCounts,
      features: state.app.features
    })
  );

  const showFiltersIcon = createAction<boolean>(
    EntitlementActions.showSearchFiltersIcon,
    dispatch
  );

  useEffect(() => {
    showFiltersIcon(false);
  }, []);

  function getActiveEntities(): IPageData<IEntity> | undefined {
    switch (pivot) {
      case EntitlementsPagePivots.Available:
        return entitlements;
      case EntitlementsPagePivots.Active:
        return validGrants;
      case EntitlementsPagePivots.Expired:
        return expiredGrants;
      default:
        return undefined;
    }
  }

  function getUrlFromPivot(p: EntitlementsPagePivots): string {
    switch (p) {
      case EntitlementsPagePivots.Available:
        return Routes.requestAccessTab;
      case EntitlementsPagePivots.Active:
        return Routes.accessIHaveTab;
      case EntitlementsPagePivots.Expired:
        return Routes.previousAccessTab;
      default:
        return Routes.requestAccessTab;
    }
  }

  function handlePivotClick(item: PivotItem | undefined): void {
    if (!item?.props) {
      return;
    }

    const nextPivot = item.props.itemKey as EntitlementsPagePivots;

    if (pivot === nextPivot) {
      return;
    }
    showFiltersIcon(false);
    history.push(getUrlFromPivot(nextPivot));
  }

  const activeEntities = getActiveEntities();
  getPageMetaDataFromEntities(activeEntities!);

  const availablePageCount = pageCounts.entitlements;
  const expiredPageCount = pageCounts.expiredGrants;
  const validPageCount = pageCounts.validGrants;

  return (
    <main
      data-automation-id="EntitlementsPage"
      className={css(detailsPageStyles.detailsPage)}
    >
      <ConnectedPageBanner title={t(LocaleKeys.accessPackage, { context: 'plural' })} />
      <div className={css(myAccessListStyles.listPage)}>
        <div className={css(myAccessStyles.paddingAround)}>
          {isSingleEntity ? (
            <div>
              <Link
                onClick={
                  // tslint:disable-next-line:jsx-no-lambda
                  () => history.push(getOneLayerUp())
                }
                className={css(ColorClassNames.black)}
              >
                <Icon iconName={'Back'} />
                <span
                  className={css(
                    FontClassNames.mediumPlus,
                    myAccessStyles.marginLeftXSmall
                  )}
                >
                  {t(pivot as unknown as LocaleKeys, {
                    context: 'capitalize',
                  })}
                </span>
              </Link>
            </div>
          ) : null}
          <h1 role="region" className={css(myAccessListStyles.pageTitle, myAccessListStyles.pageTitleH1Margin)}>
            {t(LocaleKeys.accessPackage, { context: 'plural' })}
          </h1>
          <div
            id="pageSubtitle"
            role="region"
            aria-labelledby="pageSubtitle"
            className={css(
              myAccessStyles.marginTopXSmall,
              myAccessListStyles.pageSubtitle
            )}
          >
            <span className={css(myAccessListStyles.guidStringStyle)}> {t(LocaleKeys.accessPackageGuideString)} </span>

          </div>
          {!isSingleEntity && features.isEnabled.enableAPRecommendations && <RecommendationCarousel inEntitlementPage />}
        </div>
        {isSingleEntity ? null : (
          <div
            className={css(
              'ms-DetailsPivot',
              detailsPageStyles.detailsPivot,
              myAccessStyles.subtitle
            )}
          >
            <Pivot
              selectedKey={pivot}
              onLinkClick={handlePivotClick}
              headersOnly={true}
            >
              <PivotItem
                headerText={t(LocaleKeys.available, {
                  context: 'capitalize',
                })}
                itemCount={availablePageCount}
                itemKey={EntitlementsPagePivots.Available}
              />
              <PivotItem
                headerText={t(LocaleKeys.active, {
                  context: 'capitalize',
                })}
                itemCount={validPageCount}
                itemKey={EntitlementsPagePivots.Active}
              />
              <PivotItem
                headerText={t(LocaleKeys.expired, {
                  context: 'capitalize',
                })}
                itemCount={expiredPageCount}
                itemKey={EntitlementsPagePivots.Expired}
              />
            </Pivot>
          </div>
        )}
        {children}
      </div>
    </main>
  );
}