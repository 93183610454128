import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions, IGrantRequest } from '../models';
import { useSelector } from '.';

interface IUsePartialGrantRequest {
  setPartialGrantRequest: (payload: Partial<IGrantRequest>) => void;
  partialGrantRequest: Partial<IGrantRequest> | undefined;
}

export const usePartialGrantRequest = (): IUsePartialGrantRequest => {
  const dispatch = useDispatch();
  const { partialGrantRequest } = useSelector((state) => ({
    partialGrantRequest: state?.app?.partialGrantRequest
  }));

  return {
    setPartialGrantRequest: createAction<Partial<IGrantRequest>>(EntitlementActions.setPartialGrantRequest, dispatch),
    partialGrantRequest
  };
};
