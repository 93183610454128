import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { Action, MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';
import { AdminOptInFeatureKey} from '../../../shared/features';
import { Routes } from '../../../shared/Routes';
import { EntitlementsPage, OverviewPage } from '../../../components/ELM2';
import { getCacheKey, getWithExpiry } from '../../../shared/storage/localStorageUtil';
import { isNullOrUndefined } from 'util';

export const getCachedFeaturesEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<Action>,
  _store: MiddlewareAPI<IRootEntitlementsState>
): Observable<IEntitlementAction> => {
  return action$
    // .ofType('@app/user/auth/success')
    .ofType('')
    .switchMap((_action: IEntitlementAction) => {
      const { tid } = _action.payload?.profile as { tid: string };
      const cacheKey = getCacheKey(AdminOptInFeatureKey.EnableMyAccessOverviewForEndUser, tid);
      const isOverviewEnabledInCache = getWithExpiry(cacheKey);

      if (!isNullOrUndefined(isOverviewEnabledInCache)) {
        return Observable.of({
          type: EntitlementActions.getCachedFeatures,
          payload: {
            isOverviewEnabledInCache: isOverviewEnabledInCache,
          }
        });
      } else {
        return Observable.of({
        type: EntitlementActions.getAdminOptInFeatures,
      });
    }
  });
};
registry.addEpic('getCachedFeaturesEpic', getCachedFeaturesEpic);

export const getCachedFeaturesSucceeded = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction
): Readonly<IEntitlementState> => {

  if (action.payload === undefined) {
    return state;
  }

  const features = { ...state.features.isEnabled };

  features[AdminOptInFeatureKey.EnableMyAccessOverviewForEndUser] = action.payload.isOverviewEnabledInCache;

  let newRoutes = [
    ...state.routes.slice(0, -1),
    {
      path: Routes.root,
      component: features.enableMyAccessOverviewForEndUser? OverviewPage : EntitlementsPage
    }
  ];

  return {
    ... state,
    features: {
      isLoaded: true,
      isEnabled: features,
    },
    routes: [...newRoutes]
  };
};
registry.add(
  EntitlementActions.getCachedFeatures,
  getCachedFeaturesSucceeded
);