export enum EntityType {
  entitlements = 'entitlements',
  policies = 'policies',
  catalogs = 'catalogs',
  validGrants = 'validGrants',
  expiredGrants = 'expiredGrants',
  grantRequests = 'grantRequests',
  accessReviews = 'accessReviews',
  accessPackageReviews = 'accessPackageReviews',
  aadRoleReviews = 'aadRolesReviews',
  rbacReviews = 'rbacReviews',
  byodReviews = 'byodReviews',
  userAccessReviews = 'userAccessReviews',
  accessReviewDecisions = 'accessReviewDecisions',
  secondaryDecisions = 'secondaryDecisions',
  decisionHistory = 'decisionHistory',
  decisionsCriteria = 'decisionsCriteria',
  decisionsSummary = 'decisionsSummary',
  pendingRaeRequests = 'pendingRaeRequests',
  completedRaeRequests = 'completedRaeRequests',
  expiredRaeRequests = 'expiredRaeRequests',
  pdsMember = 'pdsMember',
  pendingApprovalGrantRequests = 'pendingApprovalGrantRequests',
  completedGrantRequests = 'completedGrantRequests',
  graphObject = 'graphObject',
  virtualMachines = 'virtualMachines',
  cloudInfrastructure = 'cloudInfrastructure',
  supervisorCentricReviewDecisions = 'supervisorCentricReviewDecisions',
  supervisorCentricReviewDecisionsResources = 'supervisorCentricReviewDecisionsResources'
}
