import { css, IconButton, ResponsiveMode } from '@fluentui/react';
import { QosProvider } from '@iamexperiences/ecos-telemetry';
import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import * as React from 'react';

import { useGetPeople, useGetRecommendations, useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';
import { logOnDevelopment } from '../../../shared/consoleLogger';
import { RecommendationCard } from './RecommendationCard';
const styles = require('./RecommendationCarousel.scoped.scss');

interface RecommendationCarouselProps {
  inEntitlementPage?: boolean;
}

export const RecommendationCarousel = ({ inEntitlementPage }: RecommendationCarouselProps): JSX.Element => {
  const t = useTranslation();
  const { getRecommendations, recommendations } = useGetRecommendations();
  const { getCoworkers, loadableCoworkers, loadableDirectReports } = useGetPeople();
  const [currentStartIndex, setCurrentStartIndex] = React.useState(0);

  const responsiveMode = useResponsiveMode();
  let columnCount = 3;
  if (responsiveMode > ResponsiveMode.xxLarge && inEntitlementPage) {
    columnCount = 4;
  }
  if (responsiveMode < ResponsiveMode.xxLarge) {
    columnCount = 2;
  }
  if (responsiveMode < ResponsiveMode.large) {
    columnCount = 1;
  }

  const handlePrevClick = (): void => {
    setCurrentStartIndex(prev => Math.max(0, prev - columnCount));
  };

  const handleNextClick = (): void => {
    setCurrentStartIndex(prev => prev + columnCount);
  };

  React.useEffect(() => {
    if (recommendations.loadingState === LoadingState.notLoaded) {
      getRecommendations()
    }
  }, [recommendations.loadingState, getRecommendations])

  React.useEffect(() => {
    if (loadableDirectReports?.loadingState === LoadingState.loaded && loadableCoworkers.loadingState === LoadingState.notLoaded) {
      getCoworkers()
    }
  }, [loadableCoworkers.loadingState, loadableDirectReports?.loadingState, getCoworkers])

  if (recommendations?.loadingState === LoadingState.error) {
    logOnDevelopment(recommendations?.loadingError)
    return <></>;
  }

  let accessPackageRecommendations = recommendations?.value;
  const endIndex = currentStartIndex + columnCount;
  if (accessPackageRecommendations) {
    accessPackageRecommendations = accessPackageRecommendations.slice(currentStartIndex, Math.min(endIndex, accessPackageRecommendations.length))
  }


  if (recommendations?.loadingState === LoadingState.loading || recommendations?.loadingState === LoadingState.notLoaded || accessPackageRecommendations.length === 0) {
    return <></>;
  }

  const recommendationCardsBody = accessPackageRecommendations.map(({ accessPackageId, ...details }) => {
    if (!accessPackageId) {
      return null;
    }
    return <RecommendationCard coworkers={loadableCoworkers.value} key={accessPackageId} accessPackageId={accessPackageId} details={details} />
  });

  const prevAllowed = currentStartIndex > 0;
  const nextAllowed = recommendations?.value?.length > currentStartIndex + columnCount;
  const pageCount = Math.ceil(recommendations?.value?.length / columnCount);
  const currentPage = Math.floor(currentStartIndex / columnCount) + 1;

  return (
    <QosProvider name="RecommendationCarousel">
      <div className={css(styles.carouselWrapper, inEntitlementPage ? styles.entitlementsPage : styles.overviewPage)}>
        <div className={css(styles.title)}>
          <h2 className={css(styles.sectionTitle)}>{inEntitlementPage ? t(LocaleKeys.recommended) : t(LocaleKeys.recommendedAccessPackages)}</h2>
          <div className={css(styles.pageCount)}>
            {`${currentPage} ${t(LocaleKeys.of)} ${pageCount}`}
            <IconButton iconProps={{ iconName: 'ChevronLeft', styles: { root: styles.carouselButton } }} onClick={handlePrevClick} disabled={!prevAllowed} title={t(LocaleKeys.previous)} ariaLabel={t(LocaleKeys.previous)} />
            <IconButton iconProps={{ iconName: 'ChevronRight', styles: { root: styles.carouselButton } }} onClick={handleNextClick} disabled={!nextAllowed} title={t(LocaleKeys.next)} ariaLabel={t(LocaleKeys.next)} />
          </div>
        </div>
        <div className={css(styles.carousel)}>
          {recommendationCardsBody.filter((card) => card !== null)}
        </div>
      </div>
    </QosProvider>
  );
}