import {
  Announced,
  CheckboxVisibility,
  css,
  DefaultButton,
  MessageBar,
  MessageBarType,
  SelectionMode
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import React from 'react';

import { EntitlementActions } from '../../../models';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IGrantPolicy } from '../../../models/ELM/IGrantPolicy';
import { RequestType } from '../../../models/ELM/RequestType';
import { IListColumn } from '../../../models/IListColumn';
import { history } from '../../../redux/configureStore';
import { Routes } from '../../../shared';
import { getEntitlementUrl } from '../../../shared/getEntitlementUrl';
import { GetGrantFromEntitlement, GetGrantRequestFromGrant } from '../../../shared/GetGrantRequestFromEntity';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getSpinner } from '../../../shared/spinner';
import { getOneLayerUp } from '../../../shared/urlHelper';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { ConnectedAddGrantRequest } from '../AddGrantRequest';
import { EntitlementDetailsPanel } from '../EntitlementDetailsPanel';
import { ConnectedRequestGrantModal, ConnectedRequestGrantPanel } from '../RequestGrantPanel';
import { getEntitlementListColumns } from './EntitlementList.columns';
import { IEntitlementListProps, IEntitlementListState } from './EntitlementList.types';
import { QosProvider } from '@iamexperiences/ecos-telemetry';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

@withResponsiveMode
export class EntitlementList extends React.Component<IEntitlementListProps, IEntitlementListState> {
  constructor(nextProps: IEntitlementListProps) {
    super(nextProps);

    this.state = {
      columns: getEntitlementListColumns(
        nextProps.responsiveMode!,
        this.props.t,
        this.props.selectedFilterKey,
        this.props.isSearching,
        this.props.searchTerm,
        this.props.features,
        false,
        this.props.isGuestUser,
        this.onCtaButtonClicked,
        this.onColumnClick
      ),
      isSortedDescending: false,
      showEntitlementPanel: false
    };
  }

  public componentDidMount(): void {
    this.loadInitial();
    this.props.setMessageBarVisibility(false);
  }

  public componentWillUnmount(): void {
    this.props.setMessageBarVisibility(false);
  }

  public componentDidUpdate(prevProps: IEntitlementListProps): void {
    if (this.props.isSingleEntity !== prevProps.isSingleEntity) {
      this.loadInitial();
    }

    if (
      (!this.props.catalogId && prevProps.isSearching !== this.props.isSearching) ||
      this.props.responsiveMode !== prevProps.responsiveMode ||
      prevProps.searchTerm !== this.props.searchTerm
    ) {
      this.setState({
        ...this.state,
        columns: getEntitlementListColumns(
          this.props.responsiveMode!,
          this.props.t,
          this.props.selectedFilterKey,
          this.props.isSearching,
          this.props.searchTerm,
          this.props.features,
          this.state.isSortedDescending,
          this.props.isGuestUser,
          this.onCtaButtonClicked,
          this.onColumnClick
        )
      });
    }

    // For the single access package view, show the "Request access" side panel
    // automatically when the entitlement is loaded from server.
    if (
      this.props.isSingleEntity &&
      this.props.accessPackageId &&
      prevProps.entitlementList.length === 0 &&
      this.props.entitlementList.length > 0
    ) {
      const [entitlement] = this.props.entitlementList;
      this.setState({
        ...this.state,
        selectedEntitlement: entitlement
      });
      if (this.props.features.enableRequestGrantRefresh) {
        const grant = GetGrantFromEntitlement(entitlement);
        const grantRequest = GetGrantRequestFromGrant(grant!, RequestType.UserAdd);
        this.props.show2022GrantRequest(grantRequest);
      } else {
        this.props.showAddGrantRequest();
      }
    }
  }

  public render(): JSX.Element {
    const {
      clearValidationErrors,
      dismiss2022GrantRequest,
      dismissAddGrantRequest,
      enableNewRequestForm,
      entitlementList,
      errorHasOccurred,
      features,
      getEntitlements,
      getPoliciesForEntitlement,
      isFiltering,
      isLoading,
      isLoadingMore,
      isRefreshing,
      isSearching,
      isSingleEntity,
      isTenantWhitelisted,
      pageMetaData,
      policiesLoading,
      policyAssignments,
      searchTerm,
      selectedFilterKey,
      setMessageBarVisibility,
      show2022GrantRequest,
      showAddGrantRequest,
      showing2022GrantRequest,
      showingAddGrantRequest,
      showMessageBar,
      t,
      validationErrors,
      policies
    } = this.props;
    const spinnerLabel = t(LocaleKeys.loadingPage, {
      pageName: t(LocaleKeys.package, { context: 'plural' })
    });

    if (isRefreshing) {
      return getSpinner(spinnerLabel);
    }

    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />;
      }

      return (
        <ErrorBanner
          text={t(LocaleKeys.errorMessage)}
          onAction={getEntitlements}
          actionText={t(LocaleKeys.retry)}
        />
      );
    }

    const entitlements = entitlementList as IEntitlement[];
    const filteredCount = pageMetaData.filteredEntityCount ? pageMetaData.filteredEntityCount : 0;

    const { selectedEntitlement } = this.state;
    const grant = GetGrantFromEntitlement(selectedEntitlement);
    const grantRequest = GetGrantRequestFromGrant(grant!, RequestType.UserAdd);

    const showNoEntities =
      (pageMetaData.allEntityCount === 0 && !isRefreshing && !isSearching && !isFiltering) ||
      (entitlements.length === 0 && isSingleEntity && !isLoading);

    const showNoFilteredResults = !isLoading && isSearching && filteredCount === 0;

    const showLoadMore =
      !pageMetaData.isAllEntitiesFullyCached &&
      !isLoading &&
      !isLoadingMore &&
      !pageMetaData.isFilteredEntitiesFullyCached;

    const noEntitiesProp = isSingleEntity
      ? {
        t,
        iconName: 'Lock',
        noRowMessage: LocaleKeys.noPermissionToViewAccessPackage,
        showButton: true,
        buttonText: LocaleKeys.viewOtherAvailablePackages,
        onButtonClick: () => history.push(getOneLayerUp())
      }
      : {
        t,
        iconName: 'Unlock',
        noRowMessage: LocaleKeys.noEntitlementsMessage,
        showButton: false
      };

    const filteredPolicies: IGrantPolicy[] = [];
    if (selectedEntitlement) {
      const policyIDs = policyAssignments.get(selectedEntitlement.id);
      if (policyIDs) {
        policies.forEach((policy, policyId) => {
          if (policyIDs?.includes(policyId.toString())) {
            filteredPolicies.push(policy);
          }
        });
      }
    }

    const ConnectedRequestGrant = enableNewRequestForm ? ConnectedRequestGrantModal : ConnectedRequestGrantPanel;
    return (
      <QosProvider name='AvailableAccessPackages'>
        <div
          className={css(
            myAccessListStyles.listPage,
            myAccessListStyles.paddingSmallTop,
            myAccessListStyles.listWithHoverRow
          )}
        >
          {!isLoading && isSearching && <Announced message={`${filteredCount} items found`} />}
          <InfinityList
            t={t}
            entityList={entitlements}
            ariaLabel={'List of access packages to request'}
            columns={this.state.columns}
            showLoadMore={showLoadMore}
            showSpinner={isLoadingMore}
            spinnerLabel={spinnerLabel}
            showNoEntities={showNoEntities}
            noEntitiesProps={noEntitiesProp}
            showNoFilteredResults={showNoFilteredResults}
            onLoadMore={this.loadMore}
            onRowClicked={this.onRowClicked}
            selectionMode={SelectionMode.none}
            isExpanded={isSingleEntity}
            selectedFilterKey={selectedFilterKey}
            searchTerm={searchTerm}
            checkboxVisibility={CheckboxVisibility.hidden}
          />
          {showing2022GrantRequest && selectedEntitlement && features.enableRequestGrantRefresh ? (
            <ConnectedRequestGrant
              entitlementName={selectedEntitlement.displayName}
              entitlementDescription={selectedEntitlement.description}
              entitlementResources={selectedEntitlement.accessPackageResourceRoleScopes}
              entitlementUrl={getEntitlementUrl(selectedEntitlement.id)}
              canViewResources={selectedEntitlement.isRoleScopesVisible}
              onDismiss={() => {
                dismiss2022GrantRequest();
                this.dismissEntitlementDetailsPanel();
                clearValidationErrors();
              }}
            />
          ) : null}
          {showingAddGrantRequest && selectedEntitlement ? (
            <ConnectedAddGrantRequest
              grantRequest={grantRequest}
              entitlementName={selectedEntitlement.displayName}
              entitlementDescription={selectedEntitlement.description}
              onDismiss={() => {
                dismissAddGrantRequest();
                this.dismissEntitlementDetailsPanel();
              }}
            />
          ) : null}

          {showMessageBar ? (
            <MessageBar
              className={css(myAccessStyles.messageBar)}
              messageBarType={MessageBarType.success}
              isMultiline={false}
              onDismiss={() => setMessageBarVisibility(false)}
              actions={<DefaultButton text={t(LocaleKeys.viewPackage)} href={`#${Routes.requests}`} />}
            >
              {t(LocaleKeys.successfulRequestMessage)}
            </MessageBar>
          ) : null}

          <EntitlementDetailsPanel
            isVisible={this.state.showEntitlementPanel && !showingAddGrantRequest && !showing2022GrantRequest}
            entitlement={selectedEntitlement}
            onDismiss={this.dismissEntitlementDetailsPanel}
            hideResourcesWithMessage={
              selectedEntitlement?.isRoleScopesVisible
                ? undefined
                : t(LocaleKeys.resourcesNotVisible)
            }
            earlyPolicyLoadingEnabled={features.enableEarlyPolicyLoading}
            policies={filteredPolicies}
            policiesLoading={policiesLoading}
            hasValidationErrors={validationErrors && validationErrors.length > 0}
            getPolicies={getPoliciesForEntitlement}
            messageImageSrc="/imgs/resources_not_visible.png"
            buttons={{
              leftText: t(LocaleKeys.requestAccess, { context: 'capitalize' }),
              rightText: t(LocaleKeys.cancel),
              leftIsPrimary: true,
              onLeftClick: () => {
                if (features.enableRequestGrantRefresh) {
                  show2022GrantRequest(grantRequest);
                  this.dismissEntitlementDetailsPanel();
                } else {
                  showAddGrantRequest();
                  this.dismissEntitlementDetailsPanel();
                }
              },
              onRightClick: this.dismissEntitlementDetailsPanel
            }}
          />
        </div>
      </QosProvider>
    );
  }

  private readonly dismissEntitlementDetailsPanel = (): void => {
    this.setState({
      ...this.state,
      showEntitlementPanel: false
    });
  };

  private readonly loadInitial = (): void => {
    const { accessPackageId, catalogId } = this.props;

    if (accessPackageId) {
      // Single access package view
      this.props.filterEntitiesOnServer({ id: accessPackageId });
      this.props.setSearchContext(null);
    } else if (catalogId) {
      // View for access packages for a specific catalogs
      this.props.filterEntitiesOnServer({ selectedCatalogs: [catalogId] });
      this.props.setSearchContext(EntitlementActions.searchEntitlementsOnServer);
      this.props.showFiltersIcon(true);
    } else {
      // Unfiltered entitlement view
      this.props.refreshEntities();
      this.props.setSearchContext(EntitlementActions.searchEntitlementsOnServer);

      const urlParams = new URLSearchParams(location.search);
      if (urlParams.has('name')) {
        const searchTerm = urlParams.get('name');
        if (searchTerm) {
          this.props.onSearch(searchTerm);
        }
      }
    }
  };

  private readonly onRowClicked = (item: IEntitlement): void => {
    if (this.props.features.enableNewRequestForm) {
      this.onCtaButtonClicked(item);
    } else {
      this.setState({
        ...this.state,
        showEntitlementPanel: true,
        selectedEntitlement: item
      });
    }
  };

  private readonly onCtaButtonClicked = (item: IEntitlement): void => {
    this.setState({
      ...this.state,
      showEntitlementPanel: false,
      selectedEntitlement: item
    });
    if (this.props.features.enableRequestGrantRefresh) {
      const grant = GetGrantFromEntitlement(item);
      const grantRequest = GetGrantRequestFromGrant(grant!, RequestType.UserAdd);
      this.props.show2022GrantRequest(grantRequest);
    } else {
      this.props.showAddGrantRequest();
    }
  };

  private readonly loadMore = (): void => {
    const { catalogId } = this.props;

    if (!this.props.isLoading) {
      if (catalogId) {
        this.props.filterEntitiesOnServer(this.props.filter);
      } else if (this.props.isSearching) {
        this.searchEntitlements();
      } else {
        this.getEntitlements();
      }
    }
  };

  private readonly getEntitlements = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached) {
      return;
    }
    const nextProps = this.props;
    nextProps.getEntitlements();
  };

  private readonly searchEntitlements = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.pageMetaData.isFilteredEntitiesFullyCached) {
      return;
    }

    this.props.searchForMore();
  };

  private readonly onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IListColumn<IEntitlement>): void => {
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;

    this.setState({
      ...this.state,
      isSortedDescending: !this.state.isSortedDescending,
      columns: getNewColumnsOnColumnClicked(this.state.columns, column)
    });

    this.props.setSortedByColumn(column);
    if (!this.props.isSearching && !this.props.isFiltering) {
      this.props.sortEntitlements(column.fieldName, !column.isSortedDescending);
    } else {
      this.props.sortFilteredEntitlements(
        column.fieldName,
        !column.isSortedDescending,
        this.props.searchTerm,
        this.props.filter
      );
    }
  };
}
