import {
  ColorClassNames,
  ColumnActionsMode,
  CommandBar,
  css,
  FontClassNames,
  IColumn,
  Icon,
  IconButton,
  KeyCodes,
  IGroup,
  Link,
  SelectionMode
} from '@fluentui/react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import {
  ResponsiveMode,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { QosProvider } from '@iamexperiences/ecos-telemetry';
import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import * as React from 'react';
import { TranslationFunction } from 'react-i18next';
import { isNullOrUndefined } from 'util';

import { EntitlementActions } from '../../../models';
import { AccessReviewProviderType, AccessReviewType } from '../../../models/AccessReviews/AccessReviewType';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import {
  AccessReviewInsight,
  IAccessReviewDecisionInsights,
  IAccessReviewInsight
} from '../../../models/AccessReviews/IAccessReviewInsight';
import { IAccessReviewSubject } from '../../../models/AccessReviews/IAccessReviewSubject';
import { ISubmitDecision } from '../../../models/AccessReviews/ISubmitDecision';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { DecisionResourceType, DecisionType, ReviewDecisionSubjectType } from '../../../models/RequestApprovals/DecisionType';
import { RecommendationType } from '../../../models/RequestApprovals/RecommendationType';
import { checkFeatureAccess } from '../../../shared';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { ThrottleResponseCode } from '../../../shared/constants';
import { FormatDate } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getInlineSpinner, getSpinner } from '../../../shared/spinner';
import { ChevronButton } from '../../Shared/ChevronButton/ChevronButton';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { GroupedInfinityList } from '../../Shared/GroupedInfinityList/GroupedInfinityList';
import { AcceptRecommendations } from '../AcceptRecommendations/AcceptRecommendations';
import { ConnectedAccessReviewDetails } from '../AccessReviewDetails';
import { BulkDecisionDialog } from '../BulkDecisionDialog/BulkDecisionDialog';
import { ReviewDecisionsDialog } from '../BulkDecisionDialog/ReviewDecisionsDialog';
import { ConnectedDecisionsFilter } from '../DecisionsFilter';
import { ResetDecisions } from '../ResetDecisions/ResetDecisions';
import { ConnectedSupervisorCentricDecisionDetails } from '../SupervisorCentricDecisionDetails';
import {
  getSupervisorCentricDecisionsListCommands
} from './SupervisorCentricDecisionsList.commands';
import {
  ISupervisorCentricDecisionsListProps,
  ISupervisorCentricDecisionsListState
} from './SupervisorCentricDecisionsList.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const detailsPageStyles = require('@microsoft/portal-app/lib/styling/patterns/DetailsPage.scoped.scss');

/* Represent the list of decisions for a review. */
@withResponsiveMode
export class SupervisorCentricDecisionsList extends React.Component<
  ISupervisorCentricDecisionsListProps,
  ISupervisorCentricDecisionsListState
> {
  constructor(nextProps: ISupervisorCentricDecisionsListProps) {
    super(nextProps);
    nextProps.showFiltersIcon(false);
    this.state = {
      entitlement: undefined,
      currentDecision: undefined,
      decisionIndex: 0,
      isLoadingDetails: false,
      commands: getSupervisorCentricDecisionsListCommands(
        this.props.t,
        this.props,
        undefined
      ),
      farCommands: [],
      columns: this._getSupervisorCentricDecisionsListColumns(
        this._getResponsiveMode(),
        this.props.t
      ),
      clearSelection: false,
      resourceId: '',
      isSummaryExpanded: true,
      isSecondaryDecisionsLoaded: false,
      entityCollapsedState: {},
      selectAll: false
    };
  }

  public componentWillReceiveProps(
    nextProps: ISupervisorCentricDecisionsListProps
  ): void {
    if (
      (nextProps.currentAccessReview && this.props.currentAccessReview &&
        nextProps.currentAccessReview.id !== this.props.currentAccessReview.id) ||
      (nextProps.accessReviewDecisionList && this.props.accessReviewDecisionList &&
        nextProps.accessReviewDecisionList.length !== this.props.accessReviewDecisionList.length) ||
      (nextProps.decisionsCriteria && this.props.decisionsCriteria &&
        nextProps.decisionsCriteria.typeId !== this.props.decisionsCriteria?.typeId)
    ) {
      let newResourceId = this.state.resourceId;
      if (nextProps.decisionsCriteria &&
        nextProps.decisionsCriteria.resourceId &&
        nextProps.decisionsCriteria.resourceId.length > 0) {
        newResourceId = nextProps.decisionsCriteria.resourceId;
      }
      this.setState({
        commands: getSupervisorCentricDecisionsListCommands(
          nextProps.t,
          nextProps,
          []
        ),
        farCommands: [],
        accessReviewSelectedUsers: [],
        resourceId: newResourceId
      });
    }
  }

  public componentDidMount(): void {
    this.props.setSearchContext(
      EntitlementActions.searchReviewDecisionsOnServer
    );
    this.props.getCurrentReview();
    this.props.getDecisionsCriteria();
    if (this.props.currentAccessReview?.id) {
      this.props.getDecisionsSummary(this.props.currentAccessReview?.id!);
    }

    this.setState({
      isLoadingDetails: true,
      isSecondaryDecisionsLoaded: false
    });
  }

  public componentWillUnmount(): void {
    if (this.props.showingReviewDecisionsFilter) {
      this.props.dismissReviewDecisionsFilter();
    }

    if (this.props.showingReviewDecisionsDialog) {
      this.props.dismissReviewDecisionsDialog();
    }

    if (this.props.showingReviewDetails) {
      this.props.dismissReviewDetails();
    }

    if (this.props.showingDecisionDetails) {
      this.props.dismissDecisionDetails();
    }

    if (this.props.showingBulkDecisionDialog) {
      this.props.dismissBulkDecisionDialog();
    }

    if (this.props.showingResetDecisions) {
      this.props.dismissResetDecisions();
    }

    if (this.props.showingAcceptRecommendations) {
      this.props.dismissAcceptRecommendations();
    }
  }

  public componentDidUpdate(prevProps: ISupervisorCentricDecisionsListProps): void {
    if (
      prevProps.pageMetaData.filteredEntityCount !== this.props.pageMetaData.filteredEntityCount &&
      (prevProps.pageMetaData.isFilteredEntitiesFullyCached ||
        prevProps.pageMetaData.isAllEntitiesFullyCached)
    ) {
      this.setState({
        clearSelection: true
      });
    } else if (this.state.clearSelection) {
      this.setState({
        clearSelection: false
      });
    }

    // Set current decision
    if (this.state.decisionIndex &&
      prevProps.accessReviewDecisionList[this.state.decisionIndex] !== this.props.accessReviewDecisionList[this.state.decisionIndex]) {
      this.setState({
        currentDecision: this.props.accessReviewDecisionList[this.state.decisionIndex]
      });
    }

    // Set responsive mode
    if (prevProps.responsiveMode !== this.props.responsiveMode) {
      this._resetColumns();
    }

    // Refresh the decisions list
    if (this.state.isLoadingDetails) {
      if (!this.props.entityLoadingList[EntityType.accessReviews] && !this.props.entityLoadingList[EntityType.decisionsCriteria]) {
        this.setState({
          isLoadingDetails: false
        }, () => {
          this.props.refreshReviewDecisions(EntityType.accessReviewDecisions, EntityType.supervisorCentricReviewDecisions);
          if (this.props.currentAccessReview?.id) {
            this.props.getDecisionsSummary(this.props.currentAccessReview?.id);
          }
          this._resetColumns();
        });
      }
    } else if (!this.props.isSubmitting && prevProps.isSubmitting) {
      if (this.props.currentAccessReview?.id) {
        this.props.getDecisionsSummary(this.props.currentAccessReview?.id);
      }
    }
  }

  public render(): JSX.Element {
    const {
      isLoading,
      isRefreshing,
      errorHasOccurred,
      errorCode,
      isLoadingMore,
      showingBulkDecisionDialog,
      showingReviewDecisionsDialog,
      showingResetDecisions,
      showingAcceptRecommendations,
      t,
      isTenantWhitelisted,
      isSubmitting,
      responsiveMode,
      bulkDecisionType,
      currentAccessReview,
      decisionsCriteria
    } = this.props;

    if (isRefreshing || this.state.isLoadingDetails) {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.review)
        })
      );
    }

    if (currentAccessReview === undefined ||
      decisionsCriteria === undefined ||
      this.props.accessReviewDecisionList.length < 1) {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.review)
        })
      );
    }

    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />;
      }

      if (errorCode === ThrottleResponseCode) {
        return (
          <main
            data-automation-id="AccessReviewsListPage"
            className={css(detailsPageStyles.detailsPage)}
          >
            <ErrorBanner
              text={t(LocaleKeys.throttleError)}
            />
          </main>
        );
      }

      return (
        <ErrorBanner
          text={t(LocaleKeys.errorMessage)}
          onAction={this.props.getEntities}
          actionText={t(LocaleKeys.retry)}
        />
      );
    }

    const accessReviewDecisionList = this.props.accessReviewDecisionList as IAccessReviewDecision[];
    const processedPrincipals: Record<string, boolean> = {};

    // Create groups under each principal
    const groups: IGroup[] = [];
    let decisionIndex = 0;
    this.props.accessReviewDecisionList.forEach((decision: IAccessReviewDecision) => {

      if (!processedPrincipals[decision.principal?.id!]) {
        let length = 1;
        const decisionsList: IAccessReviewDecision[] = [];
        const key = decision.principal?.id!;

        if (decision.resource) {
          const childResourceDecisions = this.props.principalToResourceAccessReviewDecisions[decision.principal?.id!];
          length = childResourceDecisions.length;
          decisionsList.push(...childResourceDecisions);
        }

        const newGroup = {
          key,
          name: decision.principal?.displayName!,
          startIndex: decisionIndex,
          count: length,
          level: 0,
          items: decisionsList
        };
        groups.push(newGroup);
        decisionIndex += length;

        processedPrincipals[decision.principal?.id!] = true;
      }
    })

    const { isSearching, isFiltering, pageMetaData } = this.props;

    const selectionMode = SelectionMode.multiple;

    const filteredCount = pageMetaData.filteredEntityCount
      ? pageMetaData.filteredEntityCount
      : 0;

    const showNoEntities =
      pageMetaData.allEntityCount === 0 &&
      !this.props.isRefreshing &&
      !isFiltering &&
      !isSearching;

    const showNoFilteredResults =
      !isLoading && ((isFiltering || isSearching) && filteredCount === 0);

    // Display entity as whichever property is populated in decisionsCriteria
    let reviewedEntity = decisionsCriteria.groupDisplayName;
    let entityType = AccessReviewType.Group;
    if (decisionsCriteria.appDisplayName) {
      reviewedEntity = decisionsCriteria.appDisplayName;
      entityType = AccessReviewType.App;
    } else if (decisionsCriteria.accessPackageDisplayName) {
      reviewedEntity = decisionsCriteria.accessPackageDisplayName;
      entityType = AccessReviewType.AccessPackage;
    } else if (decisionsCriteria.roleDisplayName) {
      reviewedEntity = decisionsCriteria.roleDisplayName;
      if (decisionsCriteria.providerType === AccessReviewProviderType.AadRole) {
        entityType = AccessReviewType.AadRole;
      } else {
        entityType = AccessReviewType.Rbac;
      }
    }

    let decisionCriteriaSubjectType = t(LocaleKeys.user, { context: 'plural' });

    if (currentAccessReview) {
      if (decisionsCriteria.subjectType === ReviewDecisionSubjectType.ServicePrincipal.toString()) {
        decisionCriteriaSubjectType = t(LocaleKeys.servicePrincipal, { context: 'plural' });
      } else if (decisionsCriteria.subjectType === ReviewDecisionSubjectType.UserAndGroup.toString()) {
        decisionCriteriaSubjectType = t(LocaleKeys.userAndGroup, { context: 'plural' });
      }
    }

    if (currentAccessReview &&
      currentAccessReview.reviewedEntity &&
      currentAccessReview.reviewedEntity.displayName &&
      currentAccessReview.reviewedEntity.displayName.length > 0) {
      reviewedEntity = currentAccessReview.reviewedEntity.displayName;
    }

    // Count the number of reviewed decisions
    let reviewedDecisions = 0;
    if (this.props.accessReviewDecisionList !== undefined && this.props.accessReviewDecisionList.length > 0) {
      for (const decision of this.props.accessReviewDecisionList) {
        if (decision.reviewResult !== DecisionType.NotReviewed) {
          reviewedDecisions++;
        }
      }
    }

    return (
      <QosProvider name="SupervisorCentricDecisionsList">
        <div className={css(myAccessListStyles.listPage, myAccessListStyles.padding)}>

          {/** Render back link */}
          <div>
            <Link
              onClick={
                // tslint:disable-next-line:jsx-no-lambda
                () => this.props.history.push(Routes.accessReviews)
              }
              className={css(ColorClassNames.black, myAccessStyles.marginBottomSmall)}
            >
              <Icon iconName={'Back'} />
              <span
                className={css(
                  FontClassNames.mediumPlus,
                  myAccessStyles.marginLeftXSmall
                )}
              >
                {t(LocaleKeys.accessReviews)}
              </span>
            </Link>
          </div>

          {/** Title and summary section */}
          {!isNullOrUndefined(currentAccessReview) ? (
            <div>

              <div className={css(myAccessListStyles.pageTitleAlwaysDisplay)}>
                {t(currentAccessReview.displayName)}
              </div>

              <div className={css(myAccessStyles.marginTopMedium, myAccessListStyles.pageSubtitle, myAccessStyles.supervisorCentricBoxShadow)}>
                {/** Summary and grid sections */}
                <div
                  className={css(
                    myAccessStyles.supervisorCentricSummary,
                    myAccessListStyles.padding
                  )}
                  aria-label={this.props.t(LocaleKeys.userAccessReviewSummary)}
                >
                  <div className={css(myAccessStyles.floatRight, myAccessStyles.supervisorCentricChevronPlacement, myAccessStyles.chevronPointDown)}>
                    <ChevronButton t={t} onClick={this._toggleIsSummaryExpanded} />
                  </div>

                  {/** Summary header */}
                  <div className={myAccessStyles.supervisorCentricSummaryTitle}>
                    {this.props.t(LocaleKeys.userAccessReviewSummaryTitle)}
                  </div>

                  {this.state.isSummaryExpanded ? (
                    <div>
                      {/** Assignments and scope */}
                      <div className={css(myAccessStyles.floatLeft)}>

                        {/** Pending section */}
                        <div className={myAccessStyles.supervisorCentricSummarySubTitle}>
                          {this.props.t(LocaleKeys.userAccessReviewPendingTitle)}
                        </div>
                        <div className={css(myAccessStyles.supervisorCentricSummaryContent, myAccessStyles.marginBottomSmall)}>
                          {(!isNullOrUndefined(currentAccessReview.decisionsSummary))
                            ? this.props.t(LocaleKeys.userAccessReviewPendingAssignments,
                              {
                                decisionsMade: currentAccessReview.decisionsSummary.approvedCount + currentAccessReview.decisionsSummary.deniedCount + currentAccessReview.decisionsSummary.dontKnowCount,
                                totalAssignments: currentAccessReview.decisionsSummary.totalCount
                              })
                            : getInlineSpinner()}
                        </div>

                        {/** Scope section */}
                        <div className={myAccessStyles.supervisorCentricSummarySubTitle}>
                          {this.props.t(LocaleKeys.userAccessReviewScopeTitle)}
                        </div>
                        <div className={css(myAccessStyles.supervisorCentricSummaryContent)}>
                          {!isNullOrUndefined(currentAccessReview?.policy?.decisionsCriteria[0]?.principalScopeFilter) ? currentAccessReview?.policy?.decisionsCriteria[0]?.principalScopeFilter : getInlineSpinner()}
                        </div>
                      </div>

                      {/** Description */}
                      <div className={css(myAccessStyles.supervisorCentricDescriptionParent)}>
                        <div className={css(myAccessStyles.paddingAround, myAccessStyles.supervisorCentricSummaryDescriptionBorder)}>

                          <div className={css(myAccessStyles.supervisorCentricSummaryDescription)}>
                            {this.props.t(LocaleKeys.description)}
                          </div>

                          <div className={css(myAccessStyles.floatBottom, myAccessStyles.supervisorCentricSummaryContent)}>
                            {!isNullOrUndefined(currentAccessReview?.description) ? currentAccessReview?.description : getInlineSpinner()}
                          </div>

                          <div className={css(myAccessStyles.floatBottom, myAccessStyles.paddingTopSmall, myAccessStyles.supervisorCentricSummaryContent)}>
                            <span>
                              {
                                asLocalizedText(
                                  {
                                    key: LocaleKeys.pleaseReviewMultiEntities,
                                  },
                                  t
                                )
                              }
                              <Link
                                onClick={this.props.showReviewDetails}
                                className={css(
                                  FontClassNames.medium,
                                  myAccessStyles.themeDarkFont,
                                  myAccessStyles.marginLeftSmall
                                )}
                              >
                                {t(LocaleKeys.seeDetails)}
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {/** Command bar */}
          <CommandBar
            className={css(myAccessListStyles.commandBar, myAccessStyles.marginTopMedium)}
            items={this.state.commands!}
            farItems={this.state.farCommands}
          />

          {/** Decisions grid */}
          <GroupedInfinityList
            t={t}
            entityList={accessReviewDecisionList}
            groupEntityToChildren={this.props.principalToResourceAccessReviewDecisions}
            groups={groups}
            entityType={EntityType.accessReviewDecisions}
            ariaLabel={'List of users to review.'}
            clearSelection={isSubmitting || this.state.clearSelection}
            columns={this.state.columns as Array<IListColumn<IEntity>>}
            showLoadMore={false} // Do not show load more, use infinite scroll instead
            showSpinner={isLoadingMore || isRefreshing}
            spinnerLabel={t(LocaleKeys.loadingPage, {
              pageName: t(LocaleKeys.user, {
                context: 'plural'
              })
            })}
            showNoEntities={showNoEntities}
            noEntitiesProps={{
              iconName: 'UserFollowed',
              noRowMessage: LocaleKeys.completedReview,
              showButton: false
            }}
            showNoFilteredResults={showNoFilteredResults}
            onLoadMore={this._loadMore}
            onItemSelected={this._onItemSelected}
            searchTerm={this.props.searchTerm}
            selectionMode={selectionMode}
            isSubmitting={isSubmitting}
            onToggleCollapse={this._onToggleCollapseGroup}
            onToggleAllCollapse={this._onToggleAllCollapseGroup}
            onToggleSelectAll={this._onToggleSelectAll}
          />

          {/** Show bulk decision dialog */}
          {
            showingBulkDecisionDialog ? (
              <BulkDecisionDialog
                t={t}
                decisionType={bulkDecisionType}
                isSubmitting={isSubmitting}
                userList={this.state.accessReviewSelectedUsers!}
                // tslint:disable-next-line:jsx-no-lambda
                onSubmit={justification =>
                  this._patchDecision(bulkDecisionType, justification, this.state.selectAll)
                }
                onDismiss={this.props.dismissBulkDecisionDialog}
                justificationRequired={(currentAccessReview ? currentAccessReview.settings!.justificationRequiredOnApproval : true)}
                responsiveMode={responsiveMode}
                selectAll={this.state.selectAll}
                totalCount={(currentAccessReview && currentAccessReview.decisionsSummary) ? currentAccessReview.decisionsSummary.totalCount : undefined}
              />
            ) : null
          }

          {/** Showing review decisions details */}
          {
            showingReviewDecisionsDialog ? (
              <ReviewDecisionsDialog
                t={t}
                decisionSummary={this.props.currentAccessReview?.decisionsSummary}
                decisionSubjectType={checkFeatureAccess('reviewerExperience') ? decisionCriteriaSubjectType : t(LocaleKeys.user, { context: 'plural' })}
                currFilter={this.props.isFiltering ? this.props.filter : null}
                filteredEntityCount={this.props.isFiltering ? this.props.pageMetaData.filteredEntityCount || 0 : 0}
                reviewId={this.props.reviewId}
                resourceName={reviewedEntity}
                resourceType={entityType}
                isSubmitting={isSubmitting}
                submitAllDecisions={this.props.submitAllDecisions}
                onDismiss={this.props.dismissReviewDecisionsDialog}
                responsiveMode={responsiveMode}
              />) : null
          }

          {/** Show reset decisions dialog */}
          {
            showingResetDecisions ? (
              <ResetDecisions
                t={t}
                isSubmitting={isSubmitting}
                userList={this.state.accessReviewSelectedUsers!}
                // tslint:disable-next-line:jsx-no-lambda
                onSubmit={resetAll =>
                  this._patchDecision(DecisionType.NotReviewed, '', resetAll)
                }
                onDismiss={this.props.dismissResetDecisions}
                responsiveMode={responsiveMode}
              />
            ) : null
          }

          {/** Show accept recommendations dialog */}
          {
            showingAcceptRecommendations ? (
              <AcceptRecommendations
                t={t}
                userList={this.state.accessReviewSelectedUsers!}
                isSubmitting={isSubmitting}
                // tslint:disable-next-line:jsx-no-lambda
                onSubmit={acceptAll =>
                  this._patchDecision(
                    DecisionType.AcceptRecommendation,
                    '',
                    acceptAll
                  )
                }
                onDismiss={this.props.dismissAcceptRecommendations}
                responsiveMode={responsiveMode}
              />
            ) : null
          }

          <ConnectedAccessReviewDetails
            onDismiss={this.props.dismissReviewDetails}
            currentReview={currentAccessReview}
            decisionsCriteria={this.props.decisionsCriteria}
            resourceId={this.state.resourceId}
          />

          <ConnectedSupervisorCentricDecisionDetails
            isSubmitting={isSubmitting}
            onDismiss={this.props.dismissDecisionDetails}
            decision={this.state.currentDecision}
            justificationRequired={(currentAccessReview ? currentAccessReview.settings!.justificationRequiredOnApproval : true)}
            currentReview={currentAccessReview}
            insights={this._getDecisionInsights(this.state.currentDecision!)}
            lookbackDuration={this._getLookbackDuration()}
            // tslint:disable-next-line:jsx-no-lambda
            onSubmit={(type: string, justification: string) =>
              this._patchDecision(type, justification, false, true)
            }
          />

          <ConnectedDecisionsFilter
            onDismiss={this.props.dismissReviewDecisionsFilter}
            filterUpdated={this._filterUpdated}
          />
        </div>
      </QosProvider>
    );
  }

  private readonly _patchDecision = (
    type: string,
    justification: string,
    decideAll?: boolean,
    singleDecision?: boolean,
  ): void => {
    if (decideAll) {
      this.props.submitAllDecisions({
        decisionType: type,
        justification
      } as ISubmitDecision);
      this.props.refreshReviewDecisions(EntityType.accessReviewDecisions, EntityType.supervisorCentricReviewDecisions);
    } else {
      const submitDecisionList: ISubmitDecision[] = [];
      this.state.accessReviewSelectedUsers!.forEach(
        (decision: IAccessReviewDecision) => {
          // Do nothing if one of the selected reviews is unreviewed and trying to be reset
          if (decision.reviewResult === DecisionType.NotReviewed && type === DecisionType.NotReviewed) {
            return;
          }
          // There may be multiple users selected, even when using the single-decision panel
          // Only add decisions to the submitDecisionList if the id matches to the single decision
          if (!singleDecision || decision.id === this.state.currentDecision!.id) {
            let submitDecisionType = type;
            let acceptRec = false;
            if (
              type === DecisionType.AcceptRecommendation &&
              decision.accessRecommendation !== RecommendationType.NotAvailable &&
              decision.reviewResult === DecisionType.NotReviewed
            ) {
              submitDecisionType = decision.accessRecommendation!;
              acceptRec = true;
            }

            const submitDecision: ISubmitDecision = {
              decisionId: decision.id,
              decisionType: submitDecisionType,
              justification,
              acceptRecommendation: acceptRec
            };

            if (
              submitDecision.decisionType !== DecisionType.AcceptRecommendation
            ) {
              submitDecisionList.push(submitDecision);
            }
          }
        }
      );

      this.props.submitDecision(submitDecisionList);
    }

    this._afterCancelSubmitDecision();
  }

  private readonly _onItemSelected = (selectedItems: IAccessReviewDecision[]): void => {
    if (!this.props.isSubmitting) {
      this.props.dismissDecisionDetails();
    }

    this.setState({
      commands: getSupervisorCentricDecisionsListCommands(
        this.props.t,
        this.props,
        selectedItems
      ),
      accessReviewSelectedUsers: selectedItems
    });
  }

  private readonly _loadMore = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      if (this.props.isFiltering) {
        this._filterEntitiesOnServer();
      } else {
        this._getEntities(EntityType.accessReviewDecisions, EntityType.supervisorCentricReviewDecisions);
      }
    }
  }

  private readonly _getEntities = (type: string, id?: string): void => {
    if (
      this.props.pageMetaData.isAllEntitiesFullyCached ||
      this.props.isLoading
    ) {
      return;
    }
    this.props.getEntities(type, id);
  }

  private readonly _searchEntitiesOnServer = (): void => {
    if (
      this.props.pageMetaData.isAllEntitiesFullyCached ||
      this.props.pageMetaData.isFilteredEntitiesFullyCached
    ) {
      return;
    }
    this.props.searchForMore();
  }

  private readonly _filterEntitiesOnServer = (): void => {
    if (
      this.props.pageMetaData.isAllEntitiesFullyCached ||
      this.props.pageMetaData.isFilteredEntitiesFullyCached
    ) {
      return;
    }
    this.props.filterEntitiesOnServer(this.props.filter);
  }

  private readonly _afterCancelSubmitDecision = (): void => {
    this.setState({
      commands: getSupervisorCentricDecisionsListCommands(
        this.props.t,
        this.props,
        this.state.accessReviewSelectedUsers!
      )
    });
  }

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }

  private readonly _getSupervisorCentricDecisionsListColumns = (
    responsiveMode: ResponsiveMode,
    t: TranslationFunction
  ): Array<IListColumn<IAccessReviewDecision>> => {

    const isMobile = responsiveMode <= ResponsiveMode.medium;
    const primaryColumnWidth = !isMobile ? 999 : 350;

    const columns: Array<IListColumn<IAccessReviewDecision>> = [];

    // User name column
    columns.push({
      key: 'principal/displayName',
      name: t(LocaleKeys.user, { context: 'capitalize' }),
      fieldName: 'userName',
      minWidth: 200,
      maxWidth: primaryColumnWidth,
      className: 'ms-pii',
      onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
      headerClassName: FontClassNames.smallPlus,
      columnActionsMode: ColumnActionsMode.clickable,
      isSorted: false,
      isSortedDescending: false,
      isResizable: true
    });

    // Resource name
    columns.push({
      key: 'resource/displayName',
      name: t(LocaleKeys.accessReviewsResourceName, { context: 'capitalize' }),
      fieldName: 'resource/displayName',
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
      headerClassName: FontClassNames.smallPlus,
      onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
      isResizable: true,
      onRender: (item: IAccessReviewDecision) => {
        let value: string = '';

        // Just put the name of the resource or the number of resources for the principal
        if (item.resource?.displayName) {
          value = item.resource?.displayName;
        } else {
          value = '';
        }

        return (
          <ColumnValue
            searchTerm={this.props.searchTerm}
            columnValue={value}
            isHighlightRequired={false}
            isSearching={false}
          />
        );
      }
    });

    // Add non mobile columns
    if (!isMobile) {
      // Resource type
      columns.push({
        key: 'resource/type',
        name: t(LocaleKeys.accessReviewsResourceType, { context: 'capitalize' }),
        fieldName: 'resource/type',
        minWidth: 100,
        columnActionsMode: ColumnActionsMode.disabled,
        headerClassName: FontClassNames.smallPlus,
        onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
        isResizable: true,
        onRender: (item: IAccessReviewDecision) => {
          let value: string = '';;

          // Just put the type or number of types under the principal
          if (item.resource) {
            value = (item.resource as IAccessReviewSubject).type!;
          }

          return (
            <ColumnValue
              searchTerm={this.props.searchTerm}
              columnValue={value}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      });

      // Assignment type
      if (!isMobile) {
        columns.push({
          key: 'memberTypes',
          name: t(LocaleKeys.accessReviewsResourceAssignmentType, { context: 'capitalize' }),
          fieldName: 'memberTypes',
          minWidth: 150,
          columnActionsMode: ColumnActionsMode.disabled,
          headerClassName: FontClassNames.smallPlus,
          onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
          isResizable: true,
          onRender: (item: IAccessReviewDecision) => {
            let value: string = '';;

            // Just put the type or number of types under the principal
            if (item.memberTypes) {
              value = item.memberTypes;
            }

            return (
              <ColumnValue
                searchTerm={this.props.searchTerm}
                columnValue={value}
                isHighlightRequired={false}
                isSearching={false}
              />
            );
          }
        });
      }

      // Recommendation
      if (!isMobile) {
        columns.push({
          key: 'accessRecommendation',
          name: t(LocaleKeys.recommendation, { context: 'capitalize' }),
          fieldName: 'accessRecommendation',
          minWidth: 150,
          headerClassName: FontClassNames.smallPlus,
          columnActionsMode: ColumnActionsMode.disabled,
          onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
          isResizable: true,
          onRenderHeader: () => (
            <div>
              {t(LocaleKeys.recommendation, { context: 'capitalize' })}
              <TooltipHost
                content={this._renderRecommendationTooltip()}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={'recommendationTooltip'}
              >

                <IconButton
                  iconProps={{ iconName: 'Info' }}
                  onKeyDown={this._onRecommendationToolTipKeyboard}
                  ariaLabel={t(LocaleKeys.recommendation)}
                  className={css(
                    myAccessStyles.marginLeftXSmall
                  )}
                />
              </TooltipHost>
            </div>
          ),
          onRender: (item: IAccessReviewDecision) => (
            this._renderRecommendation(isMobile, item, t)
          )
        });
      }

      // Decisions
      if (!isMobile) {
        columns.push({
          key: 'reviewResult',
          name: t(LocaleKeys.decision, { context: 'capitalize' }),
          fieldName: 'reviewResult',
          minWidth: 100,
          columnActionsMode: ColumnActionsMode.disabled,
          onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
          headerClassName: FontClassNames.smallPlus,
          isResizable: true,
          onRender: (item: IAccessReviewDecision) => {
            let result: DecisionType | string = item.reviewResult!;
            switch (result) {
              case DecisionType.Approve:
                result = t(LocaleKeys.approved);
                break;
              case DecisionType.Deny:
                result = t(LocaleKeys.denied);
                break;
              case DecisionType.DontKnow:
                result = t(LocaleKeys.dontKnow);
                break;
              default:
                result = '';
                break;
            }

            return (
              <ColumnValue
                searchTerm={this.props.searchTerm}
                columnValue={result}
                isHighlightRequired={false}
                isSearching={false}
              />
            );
          }
        });
      }


      // Decision details
      columns.push({
        key: 'viewDetails',
        name: '',
        fieldName: 'id',
        minWidth: 50,
        maxWidth: 50,
        headerClassName: FontClassNames.xSmall,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: IAccessReviewDecision) => (
          <div className={css(myAccessStyles.chevronRotatePointRight)} >
            <ChevronButton t={t} onClick={() => this._viewDecisionDetails(item)} noRotate={true} />
          </div>
        )
      });
    }

    return columns;
  };

  private readonly _viewDecisionDetails = (decision: IAccessReviewDecision): void => {
    let decInd = 0;
    for (let i = 0; i < this.props.accessReviewDecisionList.length; i++) {
      if (this.props.accessReviewDecisionList[i].id === decision.id) {
        decInd = i;
        break;
      }
    }
    this.setState({
      currentDecision: decision,
      decisionIndex: decInd
    });
    this.props.showDecisionDetails(true, decision);
  };

  private readonly _renderRecommendation = (
    isMobile: boolean,
    item: IAccessReviewDecision,
    t: TranslationFunction
  ): JSX.Element => {
    let recommendation: string;
    let description = '';
    const type = this._getItemResourceType(item);

    const insightStatus = this._getDecisionInsights(item);
    const lookbackDuration = this._getLookbackDuration();

    const recommendationsEnabled: boolean = this.props.currentAccessReview && this.props.currentAccessReview.settings
      ? this.props.currentAccessReview.settings.accessRecommendationsEnabled : false;
    if (!recommendationsEnabled) {
      return (
        <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.primaryText)}>
          {t(LocaleKeys.notAvailable)}
        </div>
      );
    }

    switch (item.accessRecommendation) {
      case RecommendationType.Approve: {
        recommendation = t(LocaleKeys.approve);
        description = t(LocaleKeys.beforeReviewLess, {
          lookback: lookbackDuration
        });
        break;
      }
      case RecommendationType.Deny: {
        recommendation = t(LocaleKeys.deny);
        description = t(LocaleKeys.beforeReviewMore, {
          lookback: lookbackDuration
        });
        break;
      }
      default: {
        recommendation = t(LocaleKeys.notAvailable);
        break;
      }
    }

    if (!isMobile) {
      return (
        <div>
          <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.primaryText)}>
            {recommendation}
          </div>
          {type === DecisionResourceType.User && item.accessRecommendation !== RecommendationType.NotAvailable ? (
            <div className={css(myAccessListStyles.recommendationRow)}>
              {insightStatus.outlier ? this._renderInsight(AccessReviewInsight.Outlier) : null}
              {insightStatus.inactive ? this._renderInsight(AccessReviewInsight.Inactive) : null}
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          {item.lastUserSignInDateTime ? (
            <div className={css('ms-pii', FontClassNames.medium)}>
              {t(LocaleKeys.lastSignedIn, {
                signInDate: '(' + FormatDate(item.lastUserSignInDateTime) + ')',
                reason: description
              })}
            </div>
          ) : (
            <div className={css('ms-pii', FontClassNames.medium)}>
              {t(LocaleKeys.lastSignInUnknown)}
            </div>
          )}
          {item.reviewResult === undefined || item.reviewResult === DecisionType.NotReviewed ? (
            <span className={css('ms-pii', FontClassNames.small)}>
              {asLocalizedText(
                {
                  key: LocaleKeys.recommendationTag,
                  options: {
                    decisionType: recommendation
                  }
                },
                t
              )}
            </span>
          ) : (
            this._renderDecisionInfo(item, t)
          )}
        </div>
      );
    }
  };

  private readonly _renderDecisionInfo = (
    item: IAccessReviewDecision,
    t: TranslationFunction
  ): JSX.Element => {
    let decisionString = '';

    switch (item.reviewResult) {
      case DecisionType.Approve: {
        decisionString = LocaleKeys.decisionApproved;
        break;
      }
      case DecisionType.Deny: {
        decisionString = LocaleKeys.decisionDenied;
        break;
      }
      case DecisionType.DontKnow: {
        decisionString = LocaleKeys.decisionDontKnow;
        break;
      }
      default: {
        decisionString = LocaleKeys.decision;
        break;
      }
    }

    return (
      <span className={css('ms-pii', FontClassNames.small)}>
        {asLocalizedText(
          {
            key: decisionString,
            options: {
              reviewerName: item.reviewedBy ? item.reviewedBy.displayName : ''
            }
          },
          t
        )}
      </span>
    );
  };

  private readonly _getDecisionInsights = (item: IAccessReviewDecision): IAccessReviewDecisionInsights => {
    let isOutlier = false;

    if (item && item.insights) {
      item.insights.forEach((insight: IAccessReviewInsight) => {
        if (insight['@odata.type'] === AccessReviewInsight.Outlier) {
          isOutlier = true;
        }
      });
    }

    // relying on the inactive insight is currently unreliable, use _isUserInactive
    return {
      inactive: this._isUserInactive(item),
      outlier: isOutlier
    } as IAccessReviewDecisionInsights;
  }

  private readonly _renderInsight = (insight: AccessReviewInsight): JSX.Element | void => {

    let iconString = '';
    let message = '';

    switch (insight) {
      case AccessReviewInsight.Outlier: {
        message = this.props.t(LocaleKeys.insightCardOutlier);
        iconString = 'PeopleBlock';
        break;
      }
      case AccessReviewInsight.Inactive: {
        message = this.props.t(LocaleKeys.insightCardInactive);
        iconString = 'Snooze';
        break;
      }
      default: {
        break;
      }
    }

    return (
      <div className={css(myAccessListStyles.recommendationColumn)}>
        <Icon iconName={iconString} className={css(myAccessListStyles.recommendationIcon)} />
        {message}
      </div>
    );
  }

  private readonly _renderRecommendationTooltip = (): JSX.Element => {
    const docLink = 'https://docs.microsoft.com/azure/active-directory/governance/review-recommendations-access-reviews';
    return (
      <span className={css(myAccessListStyles.paddingSmall)}>
        {this.props.t(LocaleKeys.recommendationTooltip)}
        <Link href={docLink} target="_blank" className={css(myAccessStyles.marginLeftXSmall)}>
          {this.props.t(LocaleKeys.learnMore)}
        </Link>
      </span>
    );
  }

  private _getLookbackDuration(): number {
    let lookback = 30; // 30 is the default lookback duration
    if (this.props.currentAccessReview?.settings?.recommendationLookBackDuration) {
      let lookbackStr = this.props.currentAccessReview?.settings?.recommendationLookBackDuration;
      // Remove first and last characters (original format: P30D)
      lookbackStr = lookbackStr.substring(1, lookbackStr.length - 1);
      lookback = +lookbackStr; // convert string to number
    }
    return lookback;
  }

  private _isUserInactive(user: IAccessReviewDecision): boolean {
    if (this.props.currentAccessReview?.settings?.lastSignInRecommendationEnabled) {
      if (user && user.lastUserSignInDateTime) {
        const lookback = this._getLookbackDuration();
        const startDateTime = this.props.currentAccessReview?.startDateTime!;
        const minDay = new Date(startDateTime);
        minDay?.setDate(minDay.getDate() - lookback);
        return (new Date(user.lastUserSignInDateTime) < minDay);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private readonly _filterUpdated = (): void => {
    this.setState({
      clearSelection: true
    });
  };

  private readonly _getItemResourceType = (item: IAccessReviewDecision): DecisionResourceType => {
    if (item.groupDisplayName) {
      return DecisionResourceType.Group;
    } else if (item.servicePrincipalDisplayName) {
      return DecisionResourceType.ServicePrincipal;
    } else {
      return DecisionResourceType.User;
    }
  }

  private readonly _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IAccessReviewDecision>
  ): void => {
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;

    this.setState({
      columns: getNewColumnsOnColumnClicked(this.state.columns, column)
    });
    this.props.setSortedByColumn(column);
    if (!this.props.isSearching && !this.props.isFiltering) {
      this.props.sortEntities(column.key, !column.isSortedDescending);
    } else {
      this.props.sortFilteredEntities(
        column.key,
        !column.isSortedDescending,
        this.props.searchTerm,
        this.props.filter
      );
    }
  };

  private readonly _resetColumns = (): void => {
    this.setState({
      ...this.state,
      columns: this._getSupervisorCentricDecisionsListColumns(
        this._getResponsiveMode(),
        this.props.t
      )
    });
  }

  private readonly _toggleIsSummaryExpanded = (): void => {
    const isSummaryExpanded = !this.state.isSummaryExpanded;
    this.setState({
      isSummaryExpanded,
    });
  };

  private readonly _onToggleCollapseGroup = (group: IGroup): void => {
    if (!group.isCollapsed) {
      this.props.getSecondaryDecisions(group.key);
    }
  };

  private readonly _onToggleAllCollapseGroup = (allCollapsed: boolean): void => {
    if (!allCollapsed) {
      Object.keys(this.props.principalToResourceAccessReviewDecisions).forEach((key) => {
        this.props.getSecondaryDecisions(key);
      });
    }
  };

  private readonly _onToggleSelectAll = (selectAll = false): void => {

    if (this.props.isFiltering) {
      this.setState({
        selectAll: selectAll
      });
    }

    this.setState({
      selectAll
    });
  }

  private _onRecommendationToolTipKeyboard = (
    ev: React.KeyboardEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
    >
  ): void => {
    switch (ev.keyCode) {
      case KeyCodes.enter:
      case KeyCodes.space:
        ev.stopPropagation();
        ev.preventDefault();
        break;
      case KeyCodes.tab:
        if (ev.shiftKey) {
          ev.stopPropagation();
          ev.preventDefault();
        }
        break;
    }
  }
}
