import './polyfills';
import 'moment/min/locales';

import { initializeIcons } from '@fluentui/react';
import { QosProvider, TelemetryProvider } from '@iamexperiences/ecos-telemetry';
import { AuthProvider, WithUser } from '@iamexperiences/react-auth/lib-commonjs/react';
import { bootstrap } from '@microsoft/portal-app/lib/bootstrap';
import { Store } from '@microsoft/portal-app/lib/redux/compat';
import { setOverrideSettings } from '@microsoft/portal-app/lib/shared/override-settings/overrideSettings';
import { ErrorBoundary } from '@uifabric/portal-ux/lib/ErrorBoundary';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { ConnectedMyAccessMain } from './Application/Main';
import { auth, subscribeToErrorObservable } from './auth';
import { autoLoginHandler } from './AutoLogin/AutoLogin';
import { ConnectedContextProvider } from './context';
import { LoginError } from './LoginError';
import { configureStore } from './redux/configureStore';
import { IGlobalState } from './redux/modules/root';
import { getDomainNameFromPathName } from './shared/getDomainNameFromPathName';
import { settingsManager } from './shared/settings/settings';
import { telemetry } from './shared/telemetry';
import { AppProvider } from './Application/AppProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

const urlDomainName = getDomainNameFromPathName();
auth.init({ autoLogin: autoLoginHandler });
subscribeToErrorObservable(auth);

const settings = settingsManager.getAll();
setOverrideSettings({
  ...settings,
  authImplementation: {
    clientId: settings.adalClientId ?? '810dcf14-1858-4bf2-8134-4c369fa3235b',
    user: () => auth.user(),
    login: auth.login,
    logout: auth.logout,
    acquireToken: async (audience: string | string[]) => await auth.acquireToken(audience)
  }
});
const moment = require('moment');

initializeIcons('/');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // set the query data to stale after 5 minutes. Before it becomes stale, refetch won't occur
      retry: false,
    },
  },
});

const store: Store<IGlobalState> = configureStore();

const render = (): void => {
  ReactDOM.render(
    <TelemetryProvider telemetry={telemetry}>
        <AppProvider>
          <ConnectedContextProvider store={store}>
            <ErrorBoundary component={LoginError}>
              <QosProvider name="MyAccessMain">
                <AuthProvider auth={auth}>
                  <QueryClientProvider client={queryClient}>
                  <WithUser login={{ tenantId: urlDomainName || undefined }}>
                    <ConnectedMyAccessMain store={store} />
                  </WithUser>
                  </QueryClientProvider>
                </AuthProvider>
              </QosProvider>
            </ErrorBoundary>
          </ConnectedContextProvider>
      </AppProvider>
    </TelemetryProvider>,
    document.getElementById('root') as HTMLElement
  );
}

// To-do: moment lng should be set when i18next initialized, not based on url query params to be consistent
const urlParams = new URLSearchParams(location.search);
if (urlParams.has('lng')) {
  moment.locale(urlParams.get('lng'));
}

void Promise.resolve(true)
  .then(async () => await bootstrap())
  .then((shouldContinue: boolean) => (shouldContinue ? render() : undefined));
