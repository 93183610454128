import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IPolicyRequirementsRequestBody } from '../../../models/ELM/IGrantPolicy';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { IListColumn } from '../../../models/IListColumn';
import { LoadMode } from '../../../models/IPageData';
import { IPageMetaData } from '../../../models/IPageMetaData';
import { createTelemetryAction } from '../../../redux';
import { isInternalUser } from '../../../shared';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { telemetry } from '../../../shared/telemetry';
import { EntitlementList } from './EntitlementList';
import {
  IConnectedEntitlementListProps,
  IEntitlementListActions,
  IEntitlementListMappedProps,
  IEntitlementListRouteParams
} from './EntitlementList.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the EntitlementList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IEntitlementListProps) for the EntitlementList control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedEntitlementListProps
): IEntitlementListMappedProps => {
  const {
    showingAddGrantRequest,
    entitlements,
    searchTerm,
    errorHasOccurred,
    isTenantWhitelisted,
    showMessageBar,
    policies,
    policyAssignments,
    validationErrors
  } = state.app;

  // Access package name is the default filter
  const selectedFilterKey = state.search.selectedFilterKey ?? EntitlementSearchFilter.AccessPackage;

  const isSearching: boolean = searchTerm !== null && searchTerm !== '';

  const { accessPackageId, catalogId } = ownProps.match.params as IEntitlementListRouteParams;

  const isFiltering = !!catalogId || !!accessPackageId;

  const entitiesList = GetEntityList(entitlements, isSearching || isFiltering);

  const entitlementList: IEntitlement[] = entitiesList.filteredEntities.reduce(
    (entitlementsList: IEntitlement[], key: string) => {
      const item = entitlements.entitiesById.get(key);
      if (item !== undefined) {
        entitlementsList.push(item);
      }
      return entitlementsList;
    },
    []
  );

  const pageMetaData: IPageMetaData = getPageMetaDataFromEntities(entitlements);

  const loadMode = entitlements.loadMode!;

  const isGuestUser = !isInternalUser();

  return {
    filter: entitlements.filterContext!,
    isSearching,
    isFiltering,
    errorHasOccurred,
    isLoading: entitlements.isLoading,
    isLoadingInitial: loadMode === LoadMode.Initial,
    isRefreshing: loadMode === LoadMode.Refresh,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    entitlementList,
    showingAddGrantRequest,
    showing2022GrantRequest: state.app.partialGrantRequest?.id !== undefined,
    pageMetaData,
    searchTerm,
    selectedFilterKey,
    isTenantWhitelisted,
    isSingleEntity: !!accessPackageId,
    accessPackageId,
    catalogId,
    showMessageBar,
    policies: policies.entitiesById,
    policyAssignments,
    policiesLoading: state.app.policies.isLoading,
    validationErrors,
    navigateTo(url: string): void {
      ownProps.history.push(url);
    },
    features: state.app.features.isEnabled,
    enableNewRequestForm: state.app.features.isEnabled.enableNewRequestForm,
    isGuestUser
  };
};

/**
 * Maps the dispatch actions to the props for the EntitlementList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IEntitlementListProps) for the EntitlementList control
 */
const mapDispatchToProps = (dispatch: Dispatch<IRootEntitlementsState>): IEntitlementListActions => {
  const showAddGrantRequest = createAction<boolean>(EntitlementActions.showAddGrantRequest, dispatch);
  const show2022GrantRequest = createAction<Partial<IGrantRequest>>(
    EntitlementActions.setPartialGrantRequest,
    dispatch
  );
  const setSortedByColumnAction = createAction<{
    column: IListColumn<IEntitlement>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);
  const getEntities = createAction<{ entityType: string }>(EntitlementActions.getEntities, dispatch);
  const searchForMore = createAction<{ entityType: string }>(EntitlementActions.searchForMore, dispatch);
  const doClearValidationErrors = createAction<boolean>(EntitlementActions.clearValidationErrors, dispatch);

  const sortEntities = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);
  const sortFilteredEntities = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
    searchTerm: string;
    filterContext: IFilter;
  }>(EntitlementActions.sortFilteredEntities, dispatch);
  const refreshEntitiesAction = createAction<{ entityType: string }>(EntitlementActions.refreshEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);

  const getPoliciesForEntitlement = createAction<{
    entityId: string;
    parameters: IPolicyRequirementsRequestBody;
  }>(EntitlementActions.getPolicies, dispatch);

  return {
    searchForMore: () => searchForMore({ entityType: EntityType.entitlements }),
    getEntitlements: () =>
      getEntities({
        entityType: EntityType.entitlements
      }),
    sortEntitlements: (orderby: string, isAscending: boolean) =>
      sortEntities({
        entityType: EntityType.entitlements,
        orderby,
        isAscending
      }),
    sortFilteredEntitlements: (orderby: string, isAscending: boolean, searchTerm: string, filterContext: IFilter) =>
      sortFilteredEntities({
        entityType: EntityType.entitlements,
        orderby,
        isAscending,
        searchTerm,
        filterContext
      }),
    showAddGrantRequest: () => showAddGrantRequest(true),
    dismissAddGrantRequest: () => showAddGrantRequest(false),
    show2022GrantRequest: (entitlment: Partial<IGrantRequest> | undefined) => show2022GrantRequest(entitlment),
    dismiss2022GrantRequest: () => show2022GrantRequest(undefined),
    setSortedByColumn: (column: IListColumn<IEntitlement>) =>
      setSortedByColumnAction({
        column,
        entityType: EntityType.entitlements
      }),
    setSearchContext: createAction<string | null>(EntitlementActions.setSearchContext, dispatch),
    refreshEntities: () => refreshEntitiesAction({ entityType: EntityType.entitlements }),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.entitlements,
        filterContext: filter
      }),
    onSearch: (searchTerm: string) => {
      createAction<string>(EntitlementActions.searchEntitlementsOnServer, dispatch)(searchTerm);
    },
    recordBusinessEvent: (eventName) => dispatch(createTelemetryAction(eventName)),
    showFiltersIcon: createAction<boolean>(EntitlementActions.showSearchFiltersIcon, dispatch),
    setMessageBarVisibility: createAction<boolean>(EntitlementActions.showMessageBar, dispatch),
    getPoliciesForEntitlement: (entitlementId) => {
      telemetry.reportCustomEvent(`entitlement-list/${EntitlementActions.getPolicies}`);
      getPoliciesForEntitlement({
        entityId: entitlementId,
        parameters: {}
      });
    },
    clearValidationErrors: () => {
      doClearValidationErrors(true);
    }
  };
};

/**
 * A redux connected EntitlementList control.
 * @description Supports translation
 */
export const ConnectedEntitlementList = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(EntitlementList as any) as any);
