import { logOnDevelopment } from '../shared/consoleLogger';

const internalTenants = ['a80aafff-2a2a-4099-8fd4-bcbe5bcaff6d', '926d99e7-117c-4a6a-8031-0cc481e9da26'];

const byodWhiteListedTenants = [
  '72f988bf-86f1-41af-91ab-2d7cd011db47',
  'a80aafff-2a2a-4099-8fd4-bcbe5bcaff6d',
  '926d99e7-117c-4a6a-8031-0cc481e9da26'
];

const ramTestTenants = [
  'f188357a-9d1c-4bdb-82f0-56326b80f6d4',
  '926d99e7-117c-4a6a-8031-0cc481e9da26',
  '0ed4dca8-0d4a-49bc-a250-2594c80d9544',
  'e3932b6c-abdc-42f8-a39c-37516939277f',
  '941f20cc-2d05-4aa8-ae7c-e7885052d35b',
  '7521acbc-a68c-41e5-a975-1cf83066dd19',
  'c66e509a-c812-41ee-9c00-ae1517b0bb7e',
  'd899bfc0-682c-4bb0-b4ed-dbf6b67df4fe',
  'af5f16a9-05dd-49bb-9dc3-dbe7f8adf034',
  '2f27dc7b-6a04-40ce-8145-72c00380f7bd',
  'f3211d0e-125b-42c3-86db-322b19a65a22',
  'ccd25372-eb59-436a-ad74-78a49d784cf3',
  '327785b3-79ed-4e29-9e95-3f1c14faefe1',
  '372854e0-1db7-4a52-887f-e3576387649c',
  'f996f1ff-9455-4021-9ddd-1f0a20b82fe6'
];

const ramFullActivationFlowTestTenants = ['f188357a-9d1c-4bdb-82f0-56326b80f6d4'];

/**
 * This is the feature definition. To add a new feature, update object below.
 *
 * Value in the dict should be
 * {
 *   isAdminOptInFeature: boolean, if set to true, the key must be identical with API feature id.
 *   amount: int, % of customers where feature is activated, only works for client features. Always 0 for admin opt-in features.
 *   allowedTenants: [GUID], list of tenant ids for which the feature will be activated, only works for client features
 * }
 */
export const features = {
  enableCatalogView: { amount: 0 },
  pimAccessReviews: { amount: 0 },
  supervisorCentricAccessReviews: { amount: 0 },
  byodAccessReviews: {
    amount: 0,
    allowedTenants: [...byodWhiteListedTenants]
  },
  userAccessReviews: { amount: 0 },
  reviewInsights: { amount: 0 },
  reviewerExperience: {
    amount: 100,
    allowedTenants: [...byodWhiteListedTenants]
  },
  enableRequestGrantRefresh: {
    amount: 100,
    allowedTenants: [...internalTenants]
  },
  enableVerifiableCredential: {
    amount: 100,
    allowedTenants: [...internalTenants]
  },
  enableEarlyPolicyLoading: {
    amount: 100,
    allowedTenants: [...internalTenants]
  },
  enableMyAccessOverviewForEndUser: {
    amount: 0,
    isAdminOptInFeature: true
  },
  enableNewRequestForm: {
    amount: 100,
    allowedTenants: [...internalTenants]
  },
  enableClaimBasedTelemetry: {
    amount: 0
  },
  enableAPRecommendations: {
    amount: 0
  },
  enableRam: {
    amount: 0,
    allowedTenants: [...ramTestTenants]
  },
  enableGetPhoto: {
    amount: 0
  },
  enableELMRecordDecision: {
    amount: 50,
    allowedTenants: [...internalTenants]
  },
  enableApproverRevoke: {
    amount: 0,
    allowedTenants: [...internalTenants]
  },
  enableOBOFilter: {
    amount: 0,
    allowedTenants: [...internalTenants]
  },
  enableRamFullActivationFlow: {
    amount: 0,
    allowedTenants: [...ramFullActivationFlowTestTenants]
  }
} as const;

/**
 * The union type for available features.
 */
export type Feature = keyof typeof features;

export interface FeatureDefinition {
  amount: number;
  allowedTenants?: string[];
  isAdminOptInFeature?: boolean;
}

export type FeatureMap = {
  [key in Feature]: boolean;
};

/**
 * Interface for type composition in react props.
 */
export interface IWithFeaturesProp {
  features: FeatureMap;
}

/**
 * Feature model from myFeatures API
 */
export interface IAdminOptInFeature {
  id: string;
  isEnabled: boolean;
}

export enum AdminOptInFeatureKey {
  EnableMyAccessOverviewForEndUser = 'enableMyAccessOverviewForEndUser'
}

/**
 * Checks if a feature is enabled or not in the following order:
 *
 * 1. Feature is forcefully enabled with url flag.
 * 2. Check admin opt-in feature or not
 * 3a. Admin opt-in feature, enabled/disabled by the API return.
 * 3b. Client feature, tenant is in allow list, forcefully enabled
 * 4b. Otherwise, A/B testing => user is part of a randomized sample based on `amount` value in feature definition.
 *
 * Note: react components should be accessing the feature enablement state using the redux store instead of calling this method.
 *
 * @param feature the feature to check.
 * @param userObjectId the user id to use for the sampling pool.
 * @param tenantId the tenant id to use for the allow list check.
 * @param adminOptInFeatures the feature list from myFeaturesapi response.
 */
export function checkFeatureAccess(
  feature: Feature,
  userObjectId?: string,
  tenantId?: string,
  adminOptInFeatures?: IAdminOptInFeature[]
): boolean {
  const urlParams = new URLSearchParams(location.search);

  // 1. Check url feature flag override.
  // This is provided like this:
  // https://myaccess.microsoft.com/@<tenant>?<feature name>=true#/
  if (urlParams.has(feature)) {
    if (urlParams.get(feature) === 'true') {
      logOnDevelopment(`Feature "${feature}" enabled from URL override.`);
      return true;
    }

    if (urlParams.get(feature) === 'false') {
      logOnDevelopment(`Feature "${feature}" disabled from URL override.`);
      return false;
    }
  }

  const definition = features[feature] as FeatureDefinition;

  // 2. Check admin opt-in feature or not
  if (definition.isAdminOptInFeature === true) {
    // 3a. For admin opt-in feature, return the api result or default false
    const enabled = adminOptInFeatures?.find((f) => f.id.toLowerCase() === feature.toLowerCase())?.isEnabled ?? false;
    logOnDevelopment(`Feature "${feature} ${enabled ? 'enabled' : 'disabled'}" from admin opt-in API."`);
    return enabled;
  } else {
    // 3b. Check tenant allow list.
    if (
      tenantId &&
      definition.allowedTenants &&
      definition.allowedTenants.some((x) => x.toLowerCase() === tenantId.toLowerCase())
    ) {
      logOnDevelopment(`Feature "${feature}" enabled since tenant is in allow list.`);
      return true;
    }

    // 4b. Activate a certain percentage of users based on user object id.
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- using || to catch empty string
    const userHash = userObjectId || tenantId;
    if (userHash && Math.abs(hashCode(feature + userHash)) % 100 < definition.amount) {
      logOnDevelopment(`Feature "${feature}" enabled since user is in feature pool.`);
      return true;
    }

    logOnDevelopment(`Feature "${feature}" disabled.`);
    return false;
  }
}

// Simple hashcode function
function hashCode(str: string): number {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; ++i) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
