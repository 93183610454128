/* eslint-disable @typescript-eslint/no-var-requires */
import { css, DefaultButton, getTheme, IIconProps, Link, makeStyles } from '@fluentui/react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { QosProvider } from '@iamexperiences/ecos-telemetry';
import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import * as React from 'react';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { ConnectedPageBanner } from '../../../components/Shared/PageBanner/ConnectedPageBanner';
import { useFeatures } from '../../../hooks';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { FilterPanel } from '../FilterPanel/FilterPanel';
import { TimedMessageBar } from '../RamErrorModals/TimedMessageBar';
import { ConnectedVmList } from '../VmList/ConnectedVmList';
import { IVirtualMachinesProps } from './VirtualMachines.types';

// TODO: Augusto is working on a new way to import scss files, ignore for now. Will remove diaable rule on top when done
const detailsPageStyles = require('@microsoft/portal-app/lib/styling/patterns/DetailsPage.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const virtualMachineStyles = require('./virtualMachines.scss');

const useStyles = makeStyles(() => ({
  filterMessage: {
    marginRight: '10px',
    marginLeft: '24px',
    marginTop: '16px',
    marginBottom: '8px'
  }
}));
const filterIcon: IIconProps = {
  iconName: 'Filter',
  styles: {
    root: { color: getTheme().palette.blue }
  }
};

export const VirtualMachines: React.FunctionComponent<IVirtualMachinesProps> = (props: IVirtualMachinesProps) => {
  const [showFilterPanel, setShowFilterPanel] = React.useState(false);
  const [subscriptionFilter, setSubscriptionFilter] = React.useState<string>(props.subscriptionFilter || '');
  const [searchString, setSearchString] = React.useState<string>(props.searchTerm || '');
  const { filterMessage } = useStyles();
  const features = useFeatures();
  const filteredCount = props.virtualMachinesInResponse.filtered.count;
  const unfilteredCount = props.virtualMachinesInResponse.unfiltered.count;
  useEffect(() => {
    props.showSearchBox(false);
    return () => {
      props.showSearchBox(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applySubscriptionFilter = (subscription?: string): void => {
    const filter = {
      selectedSubscription: subscription
    };
    props.filterEntitiesOnServer(filter);
  };

  useEffect(() => {
    setSearchString(props.searchTerm || '');
    setSubscriptionFilter(props.subscriptionFilter || '');
  }, [props.searchTerm, props.subscriptionFilter]);

  return (
    <QosProvider name="MyAccessRAM">
      <main data-automation-id="CloudInfrastructurePage" className={css(detailsPageStyles.detailsPage)}>
        {features.enableRam ? (
          <>
            <ConnectedPageBanner title={props.t(LocaleKeys.cloudInfrastructure)} />
            <div className={css(myAccessListStyles.listPage)}>
              <div className={css(myAccessListStyles.padding)}>
                <h1 className={css(myAccessListStyles.pageTitle, myAccessListStyles.pageTitleH1Margin)}>
                  {props.t(LocaleKeys.cloudInfrastructure)}
                </h1>
                <div
                  id="pageSubtitle"
                  aria-level={1}
                  className={css(myAccessStyles.marginTopXSmall, myAccessListStyles.pageSubtitle)}
                >
                  <p className={css(myAccessListStyles.guidStringStyle)}>
                    {props.t(LocaleKeys.cloudInfrastructureGuide)}
                  </p>
                </div>
              </div>
              <FilterPanel
                isOpen={showFilterPanel}
                onFilterSubscription={(searchText) => {
                  applySubscriptionFilter(searchText);
                }}
                onClose={() => {
                  setShowFilterPanel(false);
                }}
                onResetFilters={() => {
                  applySubscriptionFilter();
                }}
                subscriptionFilter={subscriptionFilter}
                onChangeSubscriptionFilter={(subscription: string) => {
                  setSubscriptionFilter(subscription);
                }}
              />
              <div className={virtualMachineStyles.container}>
                <SearchBox
                  className={css(virtualMachineStyles.searchbox)}
                  value={searchString}
                  placeholder={props.t(LocaleKeys.ramSearchBoxPlaceholder)}
                  onClear={() => {
                    props.searchVirtualMachines();
                  }}
                  onSearch={(newValue) => {
                    props.searchVirtualMachines(newValue);
                  }}
                  onChange={(_, newValue) => {
                    setSearchString(newValue || '');
                  }}
                  maxLength={200}
                />
                <DefaultButton
                  iconProps={filterIcon}
                  onClick={() => {
                    setShowFilterPanel(true);
                  }}
                  text={props.t(LocaleKeys.filter, { context: 'capitalize_plural' })}
                  className={css(virtualMachineStyles.button)}
                />
              </div>
              <div className={filterMessage}>
                {(props.searchTerm || props.subscriptionFilter) && unfilteredCount && filteredCount !== undefined ? (
                  <p style={{ margin: '0' }}>
                    {props.t(LocaleKeys.ramFilterCountMessage, { filteredCount, unfilteredCount })}{' '}
                    <Link
                      onClick={() => {
                        props.searchTerm ? props.searchVirtualMachines() : applySubscriptionFilter();
                      }}
                    >
                      {props.t(LocaleKeys.ramClearFilters)}
                    </Link>
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <Route component={ConnectedVmList} />
              {props.ramErrorMessageBar ? (
                <TimedMessageBar message={props.ramErrorMessageBar} onDismiss={() => props.setRamErrorMessageBar()} />
              ) : null}
            </div>
          </>
        ) : (
          <ErrorBanner text={props.t(LocaleKeys.ramTenantUnavailableWarningMessage)} />
        )}
      </main>
    </QosProvider>
  );
};
