import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';

import {
  css,
  IObjectWithKey,
  Label,
  PanelType,
  PrimaryButton,
  Selection,
  SelectionMode,
  Slider,
  Panel
} from '@fluentui/react';
import {
  ResponsiveMode,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { IKeyValuePair } from '../../../models/ELM/IKeyValuePair';
import { RequestState, RequestedFor } from '../../../models/ELM/RequestState';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { FilterList } from '../../Shared/FilterList/FilterList';
import { DaysSinceSubmitted } from './DaysSinceSubmitted';
import {
  IGrantRequestFilterProps,
  IGrantRequestFilterState
} from './GrantRequestFilter.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

@withResponsiveMode
export class GrantRequestFilter extends React.Component<
IGrantRequestFilterProps,
IGrantRequestFilterState
> {
  private _hasMounted: boolean;
  private _requestStateSelection: Selection;
  private _requestStateOptions: IKeyValuePair[];
  private _requestedForSelection: Selection;
  private _requestedForOptions: IKeyValuePair[];
  private _daysSinceSubmitted: number;

  constructor(nextProps: IGrantRequestFilterProps) {
    super(nextProps);

    this._hasMounted = false;

    this._requestStateOptions = this._initSelectionOptions(RequestState);
    this._requestStateSelection = this._initSelection(
      this._requestStateOptions,
      this.props.selectedStates
    );

    this._requestedForOptions = this._initSelectionOptions(RequestedFor);
    this._requestedForSelection = this._initSelection(
      this._requestedForOptions,
      this.props.selectedRequestedFor
    );

    this.state = {
      sliderValue: 0,
      sliderTitle: DaysSinceSubmitted[0].title
    };
    this._daysSinceSubmitted = DaysSinceSubmitted[0].days;
  }

  public componentDidMount(): void {
    this._hasMounted = true;
  }

  public render(): JSX.Element {
    const { hidden, onDismiss, t } = this.props;

    return (
      <Panel
        isBlocking={false}
        isOpen={!hidden}
        onDismiss={onDismiss}
        type={PanelType.smallFixedFar}
        headerText={t(LocaleKeys.filterUpper, {
          context: 'plural'
        })}
        onRenderFooterContent={this._onRenderFooterContent}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        hasCloseButton={true}
      >
        {this._getDaysSinceSubmittedFilter()}
        {this.props.features.enableOBOFilter?
          <FilterList
            t={t}
            selection={this._requestedForSelection}
            options={this._requestedForOptions}
            localizedLabel={t(LocaleKeys.requestedFor)}
            translateOptions={true}
          /> : null
        }
        <FilterList
          t={t}
          selection={this._requestStateSelection}
          options={this._requestStateOptions}
          localizedLabel={t(LocaleKeys.requestState)}
          translateOptions={true}
        />
      </Panel>
    );
  }

  private _getDaysSinceSubmittedFilter = (): JSX.Element => {
    return (
      <div className={css(myAccessStyles.marginBottomMedium)}>
        <Label
          className={css(
            myAccessStyles.marginBottomSmall,
            myAccessStyles.semiBoldText
          )}
        >
          {this.props.t(this.state.sliderTitle)}
        </Label>
        <Slider
          min={0}
          max={6}
          step={1}
          value={this.state.sliderValue}
          showValue={false}
          onChange={this._onSliderChanged}
          ariaValueText = {this._sliderArialValueText}
        />
      </div>
    );
  }

  private _onSliderChanged = (value: number) => {
    this.setState({
      sliderValue: value,
      sliderTitle: DaysSinceSubmitted[value].title
    });
    this._daysSinceSubmitted = DaysSinceSubmitted[value].days;
    this._applyFilters();
  };

  // tslint:disable-next-line:no-any
  private _initSelectionOptions(enums: any): IKeyValuePair[] {
    const list = Object.keys(enums).filter(
      // tslint:disable-next-line:no-any
      (k: any) => typeof enums[k] === 'string'
    ) as string[];
    return list.map((item: string) => {
      const itemWithKey: IKeyValuePair = {
        key: item,
        value: enums[item]
      };
      return itemWithKey;
    });
  }

  private _initSelection(
    options: IObjectWithKey[],
    selectedItems: string[] | undefined
  ): Selection {
    let selection = new Selection({
      onSelectionChanged: this._onSelectionChanged,
      selectionMode: SelectionMode.multiple
    });

    selection.setItems(options);
    if (selectedItems === undefined) {
      selection.setAllSelected(false);
    } else {
      selectedItems.forEach((item: string) => {
        selection.setKeySelected(item, true, true);
      });
    }

    return selection;
  }

  private _onSelectionChanged = (): void => {
    if (this._hasMounted) {
      this.forceUpdate();
      this._applyFilters();
    }
  }

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }

  private _onRenderFooterContent = (): JSX.Element => {
    const responsiveMode = this._getResponsiveMode();
    return (
      <div>
        <PrimaryButton
          onClick={this._resetFilter}
          text={this.props.t(LocaleKeys.reset, { context: 'capitalize' })}
        />
        {responsiveMode < ResponsiveMode.medium ? (
          <div className={css(myAccessStyles.marginBottomMedium)} />
        ) : null}
      </div>
    );
  }

  private _applyFilters = (): void => {
    const selectedStates: string[] = Array.from(
      this._requestStateSelection.getSelection(),
      (state: IObjectWithKey) => state.key!.toString()
    );

    const selectedRequestedFor: string[] = Array.from(
      this._requestedForSelection.getSelection(),
      (state: IObjectWithKey) => state.key!.toString()
    )
    const filter = {
      selectedStates: selectedStates as string[],
      daysSinceSubmitted: this._daysSinceSubmitted,
      selectedRequestedFor: selectedRequestedFor as string[]
    };

    if (this.props.pageMetaData.isAllEntitiesFullyCached) {
      this.props.filterEntities(filter);
    } else {
      this.props.filterEntitiesOnServer(filter);
    }
  }

  private _resetFilter = (): void => {
    this._daysSinceSubmitted = DaysSinceSubmitted[0].days;
    this.setState({
      sliderValue: 0,
      sliderTitle: DaysSinceSubmitted[0].title
    });
    this._requestStateSelection.setAllSelected(false);
    this._applyFilters();
  }

  private _sliderArialValueText = (value: number) => DaysSinceSubmitted[value].title;
}
