import { INavLink, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { TranslationFunction } from 'react-i18next';
import { RouteProps } from 'react-router-dom';

import { ConnectedAccessReviewDecisionsList } from '../components/AccessReviews/AccessReviewDecisionsList';
import { ConnectedSupervisorCentricDecisionsList } from '../components/AccessReviews/SupervisorCentricDecisionsList';
import { ConnectedAccessReviewsList } from '../components/AccessReviews/AccessReviewsList';
import { ConnectedCatalogsPage } from '../components/ELM/CatalogsPage';
import { ConnectedGrantRequestList } from '../components/ELM/GrantRequestList';
import { ConnectedGrantRequests } from '../components/ELM/GrantRequests';
import { EntitlementsPage, OverviewPage } from '../components/ELM2';
import { CloudInfrastructurePage } from '../components/RAM/CloudInfrastructurePage/CloudInfrastructurePage';
import { ConnectedDiagnosticsPanel } from '../components/Shared/DiagnosticsPanel';
import { canShowUserInitiatedIPF } from '../InProductFeedback';
import { FeatureMap, isInternalUser, LocaleKeys, Routes } from '../shared';
export type INavLinkFunction = (t: TranslationFunction) => INavLink;

const myAccesslinks = [] as INavLinkFunction[];

export const getNavigation = (
  t: TranslationFunction,
  features: FeatureMap,
  onShowInProductFeedback: VoidFunction
): INavLinkGroup[] => {
  const links: INavLink[] = [
    {
      key: 'overview',
      name: t(LocaleKeys.overviewUpper),
      url: Routes.overview,
      icon: 'Home'
    },
    {
      key: 'accessPackages',
      name: t(LocaleKeys.accessPackage, { context: 'plural' }),
      url: Routes.accessPackages,
      icon: 'Package'
    } as INavLink,
    {
      name: t(LocaleKeys.requestHistory),
      url: Routes.requests,
      icon: 'History'
    },
    {
      key: 'approvals',
      name: t(LocaleKeys.approvalUpper, { context: 'plural' }),
      url: Routes.requestApprovals,
      icon: 'AddFriend',
      disabled: !isInternalUser()
    },
    {
      key: 'accessReviews',
      name: t(LocaleKeys.accessReviews),
      url: Routes.accessReviews,
      icon: 'MultiSelect',
      disabled: !isInternalUser()
    },
    ...(features.enableRam && isInternalUser()
      ? [
          {
            key: 'ram',
            name: t(LocaleKeys.cloudInfrastructure),
            url: Routes.ram,
            icon: 'Cloud'
          }
        ]
      : []),
    ...(isInternalUser() && canShowUserInitiatedIPF()
      ? [
          {
            key: 'inProductFeedback',
            name: t(LocaleKeys.inProductFeedback),
            url: '',
            icon: 'Feedback',
            forceAnchor: true,
            onClick: () => {
              onShowInProductFeedback();
            }
          }
        ]
      : []),
    ...myAccesslinks.map((link: INavLinkFunction) => link(t))
  ];

  if (features.enableCatalogView) {
    links.splice(1, 0, {
      key: 'catalogs',
      name: t(LocaleKeys.catalogUpper, { context: 'plural' }),
      url: Routes.catalogs,
      icon: 'Folder'
    });
  }

  return [{ links }];
};

export const getRoutes = (): RouteProps[] => {
  const routes: RouteProps[] = [
    {
      path: Routes.overview,
      component: OverviewPage
    },
    {
      path: Routes.accessPackages,
      component: EntitlementsPage,
      strict: true
    },
    {
      path: `${Routes.catalogs}${Routes.catalogId}`,
      component: ConnectedCatalogsPage
    },
    {
      path: Routes.catalogs,
      component: ConnectedCatalogsPage
    },
    {
      path: Routes.requests,
      component: ConnectedGrantRequestList,
      exact: true
    },
    {
      path: Routes.requestApprovals,
      component: ConnectedGrantRequests,
      strict: true
    },
    {
      path: `${Routes.accessReviews}${Routes.reviewId}`,
      component: ConnectedAccessReviewDecisionsList,
      strict: true
    },
    {
      path: `${Routes.supervisorCentricReviews}${Routes.reviewId}`,
      component: ConnectedSupervisorCentricDecisionsList,
      strict: true
    },
    {
      path: Routes.accessReviews,
      component: ConnectedAccessReviewsList,
      strict: true
    },
    {
      path: Routes.ram,
      component: CloudInfrastructurePage
    },
    {
      path: Routes.me,
      component: ConnectedDiagnosticsPanel,
      strict: true
    },
    {
      path: Routes.root,
      component: OverviewPage
    }
  ];

  return routes;
};

export const addLink = (linkToAdd: INavLinkFunction) => {
  myAccesslinks.push(linkToAdd);
};
