// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2DjobfJijiHCgghgftasya{display:flex;flex-flow:row;padding:8px;align-items:center;width:inherit;background-color:white;text-align:center}._2DjobfJijiHCgghgftasya:hover{cursor:pointer;background-color:transparent}.QvY3XwsHABjpcVdYwezsn{display:flex;flex-flow:row;padding:16px;align-items:center;width:inherit;background-color:white;text-align:center}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/PageBanner/PageBanner.scoped.scss"],"names":[],"mappings":"AAAA,yBACE,YAAa,CACb,aAAc,CACd,WAAY,CACZ,kBAAmB,CACnB,aAAa,CACb,sBAAsB,CACtB,iBAAkB,CACnB,+BAEC,cAAe,CACf,4BAA6B,CAC9B,uBAEC,YAAa,CACb,aAAc,CACd,YAAa,CACb,kBAAmB,CACnB,aAAa,CACb,sBAAuB,CACvB,iBAAkB","sourcesContent":[".bannerContainer{\n  display: flex;\n  flex-flow: row;\n  padding: 8px;\n  align-items: center;\n  width:inherit;\n  background-color:white;\n  text-align: center;\n}\n.bannerContainer:hover{\n  cursor: pointer;\n  background-color: transparent;\n}\n.emergencyBannerContainer{\n  display: flex;\n  flex-flow: row;\n  padding: 16px;\n  align-items: center;\n  width:inherit;\n  background-color: white;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": "_2DjobfJijiHCgghgftasya",
	"emergencyBannerContainer": "QvY3XwsHABjpcVdYwezsn"
};
module.exports = ___CSS_LOADER_EXPORT___;
