import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';

import {
  css,
  PrimaryButton,
  TextField
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';
import {
  IDiagnosticsPanelProps,
  IDiagnosticsPanelState
} from './DiagnosticsPanel.types';
import { ConnectedPageBanner } from '../../Shared/PageBanner/ConnectedPageBanner';

const copy = require('clipboard-copy');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

@withResponsiveMode
export class DiagnosticsPanel extends React.Component<
  IDiagnosticsPanelProps,
  IDiagnosticsPanelState
> {
  constructor(nextProps: IDiagnosticsPanelProps) {
    super(nextProps);
    this.state = {
      isCopied: false
    };
  }

  public componentDidMount(): void {
    this.props.getMember();
  }

  public render(): JSX.Element {
    return (
      <div
        className={css(myAccessListStyles.listPage, myAccessListStyles.padding)}
      >
        <ConnectedPageBanner title={t(LocaleKeys.diagnostics)} />
        {this._onRenderBody()}
      </div>
    );
  }

  private _onRenderBody = (): JSX.Element => {
    const { t, member, isLoading, errorHasOccurred } = this.props;

    if (isLoading) {
      return (
        <div className={css(myAccessStyles.marginTopXLarge)}>
          {getSpinner(t(LocaleKeys.loading))}
        </div>
      );
    }

    if (errorHasOccurred) {
      return (
        <ErrorBanner
          text={t(LocaleKeys.errorMessage)}
          onAction={this.props.getMember}
          actionText={t(LocaleKeys.retry)}
        />
      );
    }

    const valueToCopy = JSON.stringify(member, null, 4);

    const buttonText = this.state.isCopied
      ? LocaleKeys.copied
      : LocaleKeys.copy;

    return (
      <div>
        <div
          className={css(
            myAccessListStyles.pageTitle,
            myAccessStyles.marginBottomMedium
          )}
        >
          {t(LocaleKeys.diagnostics)}
        </div>
        <TextField
          multiline
          autoAdjustHeight
          value={valueToCopy}
          disabled={true}
          className={css('ms-pii')}
        />
        <PrimaryButton
          className={css(myAccessStyles.marginTopSmall)}
          data-clipboard-text={valueToCopy}
          text={t(buttonText)}
          disabled={this.state.isCopied}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => this._onCopyClicked(valueToCopy)}
        />
      </div>
    );
  }

  private _onCopyClicked = (value: string): void => {
    copy(value).then(() => {
      this.setState({
        isCopied: true
      });
    });
  }
}
