import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { ReducerRegistry } from '@microsoft/portal-app/lib/redux/ReducerRegistry';

import { getRoutes } from '../../Application/MyAccessRouting';
import { IEntitlementAction } from '../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../models/IEntitlementState';
import { defaultState } from './defaultState';

// Initialize the registry with items that cannot be initialized statically.
export const initialState: IEntitlementState = {
  ...defaultState,
  routes: getRoutes()
};

export const registry = new ReducerRegistry<IEntitlementAction<AnyPayload>, IEntitlementState, IRootEntitlementsState>(
  initialState
);
