import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';
import { EntitlementActions } from '../models';
import { useSelector } from '.';

export const useGetRecommendations = () => {
  const { recommendations } = useSelector((state) => ({
    recommendations: state.app.recommendations
  }));
  const dispatch = useDispatch();
  const getRecommendations = createAction(EntitlementActions.getRecommendations, dispatch);
  return { getRecommendations, recommendations };
};
