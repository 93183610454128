import {
  css,
  DefaultButton,
  FocusZone,
  FocusZoneDirection,
  List,
  Pivot,
  PivotItem,
  ResponsiveMode,
  Text
} from '@fluentui/react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import * as React from 'react';

import { TruncatedTextSingleLine } from '../../../components/Shared/TruncatedText/TruncatedText';
import { useTranslation } from '../../../hooks';
import { isEmptyOrUndefined, LocaleKeys } from '../../../shared';
import { OnBehalfOfComboBox } from '../OnBehalfOfComboBox';
import { ResourceListItem } from './ResourceListItem';
import { AllResourceTypeKey } from './ResourceView.constants';
import { IResourceItem, IResourceViewProps } from './ResourceView.types';
import {
  convertResourceTypeToDropdownOption,
  convertRoleScopeToListItem,
  filterByResourceType,
  generateDropdownOptions
} from './ResourceView.utilities';

const styles = require('./ResourceView.scoped.scss');

const onRenderCell = (item?: IResourceItem | null, index?: number): React.ReactNode => {
  if (!item || index === undefined) {
    return null;
  }
  return <ResourceListItem key={index} item={item} index={index} />;
};

const shareButtonId = 'share-button-id';
function updateClipboard(newClip: string): void {
  navigator.clipboard.writeText(newClip).catch(() => {
    // TODO: add telemetry for errors
  });
}

export function ResourceView({
  entitlementResources: entitlementResourcesFromProps,
  entitlementDescription,
  canViewResources,
  entitlementUrl,
  oboAllowed,
  showCopyLinkNotification
}: IResourceViewProps): JSX.Element {
  const t = useTranslation();
  const { dropdownOptions, allResourcesItem, entitlementResources } = React.useMemo(() => {
    const entitlementResources = isEmptyOrUndefined(entitlementResourcesFromProps) ? [] : entitlementResourcesFromProps;
    const allResourcesOption = convertResourceTypeToDropdownOption({
      key: AllResourceTypeKey,
      count: entitlementResources.length,
      displayName: t(LocaleKeys.allResources)
    });
    const options = generateDropdownOptions(t, entitlementResources);
    options?.unshift(allResourcesOption);
    return { dropdownOptions: options, allResourcesItem: allResourcesOption, entitlementResources };
  }, [entitlementResourcesFromProps, t]);

  const [selectedResourceType, setSelectedResourceType] = React.useState<IDropdownOption>(allResourcesItem);

  const processedEntitlementResources = React.useMemo(() => {
    const filteredElements = filterByResourceType(entitlementResources, selectedResourceType?.key);
    return filteredElements.map(convertRoleScopeToListItem);
  }, [entitlementResources, selectedResourceType]);

  const handleResourceTypeChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setSelectedResourceType(option);
    }
  };

  const shareLink = (): void => {
    updateClipboard(entitlementUrl);
    showCopyLinkNotification();
  };

  const noResources = entitlementResources.length === 0;
  return (
    <Pivot aria-label={t(LocaleKeys.accessPackagePivot)}>
      <PivotItem headerText={t(LocaleKeys.requestDetails)}>
        <div className={css(styles.requestDetails)}>
          <TruncatedTextSingleLine textComponentProps={{ className: css(styles.requestDescription) }} text={entitlementDescription ?? ''} readMoreLabel={t(LocaleKeys.readMore)} />
          {oboAllowed && <OnBehalfOfComboBox />}
          <div className={css(styles.shareLink)}>
            <Text className={css(styles.shareLinkLabel)}>{oboAllowed ? t(LocaleKeys.shareLink) : t(LocaleKeys.shareLinkNoOr)}</Text>
            <DefaultButton onClick={shareLink} id={shareButtonId} text={t(LocaleKeys.copyLink)} iconProps={{ iconName: 'Link' }} />
          </div>
        </div>
      </PivotItem>
      {canViewResources && (
        <PivotItem headerText={t(LocaleKeys.resources)}>
          <Dropdown
            label={t(LocaleKeys.viewBy)}
            options={dropdownOptions}
            selectedKey={selectedResourceType?.key ?? AllResourceTypeKey}
            onChange={handleResourceTypeChange}
            responsiveMode={ResponsiveMode.large}
            styles={{
              root: css(styles.dropdownWrapper),
              dropdown: css(styles.dropdown)
            }}
            disabled={noResources}
          />
          <FocusZone direction={FocusZoneDirection.vertical}>
            {noResources ? (
              <div className={css(styles.noResources)}>
                <img src="/imgs/feature_NoResources.svg" alt={t(LocaleKeys.noResourcesHere)} width={160} />
                <Text>{t(LocaleKeys.noResourcesHere)}</Text>
              </div>
            ) : (
              <div className={css(styles.listContainer)} data-is-scrollable>
                <List items={processedEntitlementResources} onRenderCell={onRenderCell} />
              </div>
            )}
          </FocusZone>
        </PivotItem>
      )}
    </Pivot>
  );
}
