
import { css, Pivot, PivotItem } from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { ConnectedPageBanner } from '../../Shared/PageBanner/ConnectedPageBanner';
import { IEntity } from '../../../models/ELM/IEntity';
import { IPageData } from '../../../models/IPageData';
import { history } from '../../../redux/configureStore';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { Subtitle } from '../../Shared/Subtitle/Subtitle';
import { ConnectedCompletedGrantRequestList } from '../CompletedGrantRequestList';
import { ConnectedPendingApprovalGrantRequestList } from '../PendingApprovalGrantRequestList';
import {
  GrantRequestsPivots,
  IGrantRequestsProps,
  IGrantRequestsState
} from './GrantRequests.types';

const detailsPageStyles = require('@microsoft/portal-app/lib/styling/patterns/DetailsPage.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

@withResponsiveMode
export class GrantRequests extends React.Component<
  IGrantRequestsProps,
  IGrantRequestsState
> {
  constructor(nextProps: IGrantRequestsProps) {
    super(nextProps);
    this.state = {
      selectedPivot: GrantRequestsPivots.Pending
    };
    nextProps.setSearchContext(null);
    nextProps.showFiltersIcon(false);
  }

  public componentWillReceiveProps(): void {
    this.setState({
      selectedPivot: this._getSelectedPivotFromUrl()
    });
  }

  public render(): React.ReactNode {
    const { t, searchTerm } = this.props;

    const activeEntities = this._getActiveEntities();

    return (
      <main
        data-automation-id="GrantRequestsPage"
        className={css(detailsPageStyles.detailsPage)}
      >
        <ConnectedPageBanner title={t(LocaleKeys.approvalUpper, { context: 'plural' })} />
        <div className={css(myAccessListStyles.listPage)}>
          <div className={css(myAccessListStyles.padding)}>
            <h1 className={css(myAccessListStyles.pageTitle, myAccessListStyles.pageTitleH1Margin)}>
              {t(LocaleKeys.approvalUpper, { context: 'plural' })}
            </h1>
            <div
              id="pageSubtitle"
              role="heading"
              aria-labelledby="pageSubtitle"
              className={css(
                myAccessStyles.marginTopXSmall,
                myAccessListStyles.pageSubtitle
              )}
            >
              <Subtitle
                isSearching={!isEmptyOrUndefined(searchTerm)}
                isFiltering={false}
                pageMetaData={getPageMetaDataFromEntities(activeEntities!)}
                searchTerm={searchTerm}
                entityName={this._getEntityName()}
                t={this.props.t}
              />
            </div>
          </div>
          <div
            className={css('ms-DetailsPivot', detailsPageStyles.detailsPivot)}
          >
            <Pivot
              selectedKey={this.state.selectedPivot}
              onLinkClick={this._handlePivotClick}
              headersOnly={true}
            >
              <PivotItem
                linkText={this.props.t(LocaleKeys.pending, {
                  context: 'capitalize'
                })}
                itemKey={GrantRequestsPivots.Pending}
              />
              <PivotItem
                linkText={this.props.t(LocaleKeys.history, {
                  context: 'capitalize'
                })}
                itemKey={GrantRequestsPivots.History}
              />
            </Pivot>
          </div>
          {this._renderGrantRequestsPivot()}
        </div>
      </main>
    );
  }

  private _getSelectedPivotFromUrl(): GrantRequestsPivots {
    if (location.hash.includes(Routes.requestPendingTab)) {
      return GrantRequestsPivots.Pending;
    }

    if (location.hash.includes(Routes.requestCompletedTab)) {
      return GrantRequestsPivots.History;
    }

    return GrantRequestsPivots.Pending;
  }

  private _getUrlFromSelected = (selectedPivot: GrantRequestsPivots): string => {
    switch (selectedPivot) {
      case GrantRequestsPivots.Pending:
        return Routes.requestPendingTab;
      case GrantRequestsPivots.History:
        return Routes.requestCompletedTab;
      default:
        return Routes.requestPendingTab;
    }
  }

  private _getEntityName = (): string => {
    switch (this.state.selectedPivot) {
      case GrantRequestsPivots.Pending:
        return LocaleKeys.pending;
      case GrantRequestsPivots.History:
        return LocaleKeys.completedApproval;
      default:
        return '';
    }
  }

  private _getActiveEntities = (): IPageData<IEntity> | undefined => {
    const { pendingApprovalGrantRequests, completedGrantRequests } = this.props;
    switch (this.state.selectedPivot) {
      case GrantRequestsPivots.Pending:
        return pendingApprovalGrantRequests;
      case GrantRequestsPivots.History:
        return completedGrantRequests;
      default:
        return undefined;
    }
  }

  private _renderGrantRequestsPivot = (): JSX.Element => {
    switch (this.state.selectedPivot) {
      case GrantRequestsPivots.Pending:
        return <Route component={ConnectedPendingApprovalGrantRequestList} />;
      case GrantRequestsPivots.History:
        return <Route component={ConnectedCompletedGrantRequestList} />;
      default:
        return <Route component={ConnectedPendingApprovalGrantRequestList} />;
    }
  }

  private _handlePivotClick = (item: PivotItem | undefined): void => {
    if (!item || !item.props) {
      return;
    }

    if (
      this.state.selectedPivot === (item.props.itemKey as GrantRequestsPivots)
    ) {
      return;
    }

    const selectedPivot = item.props.itemKey as GrantRequestsPivots;

    this.setState({
      selectedPivot: selectedPivot
    });

    history.push(this._getUrlFromSelected(selectedPivot));
  }
}
