import { Icon, css, KeyCodes } from '@fluentui/react';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { useCallback } from 'react';
import * as React from 'react';
import { IPageBannerActions } from './ConnectedPageBanner';

const pageBannerStyles = require('./PageBanner.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

interface IPageBannerProps extends IPageBannerActions {
  title: string;
}

export const PageBanner: React.FC<IPageBannerProps> = ({ expandNav, title }) => {
  const responsiveMode = useResponsiveMode();

  const menuClick = useCallback(() => {
    expandNav();
  }, [expandNav]);

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    switch(ev.keyCode) {
      case KeyCodes.enter:
        ev.preventDefault();
        ev.stopPropagation();
        menuClick();
        break;
    }
  };

  return responsiveMode <= ResponsiveMode.large ? (
    <div
      className={css(pageBannerStyles.bannerContainer)}
      role="button"
      arial-aria-label={title}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={menuClick}
    >
      <Icon iconName="NumberedListText"/>
      <span className={css(myAccessStyles.marginLeftSmall)}>{title}</span>
    </div>
  ) : null;
};