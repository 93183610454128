// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--neutralPrimary: #323130 !important;--neutralSecondary: #605e5c !important}._2UBw0AuQzwBtTOGGYWZde7{background-color:rgba(255,255,255,0.2) !important}\n", "",{"version":3,"sources":["webpack://./src/css/global.scss"],"names":[],"mappings":"AAGA,MACE,oCAAiB,CACjB,sCAAmB,CACpB,yBAGC,iDAAqD","sourcesContent":["@import '~@microsoft/portal-app/lib/styling/mixins.scss';\n@import '~@fluentui/react/dist/sass/mixins/_Font.Mixins.scss';\n\n:root {\n  --neutralPrimary: #323130 !important;\n  --neutralSecondary: #605e5c !important;\n}\n\n.ms-Overlay {\n  background-color: rgba(255, 255, 255, 0.2) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ms-Overlay": "_2UBw0AuQzwBtTOGGYWZde7"
};
module.exports = ___CSS_LOADER_EXPORT___;
