import { App } from '@microsoft/portal-app/lib/App/App';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { IRootEntitlementsState } from '../../models/IEntitlementState';
import { IAppActions, IAppMappedProps, IConnectedAppProps } from './App.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the App control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IAppProps) for the App control
 */
export const mapStateToConnectedAppProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedAppProps
): IAppMappedProps => {
  return {
    ...ownProps,
    appBarProps: {
      ...ownProps.appBarProps,
      searchPlaceholderText:
        ownProps.t && ownProps.t!(state.app.searchPlaceholderText.key)
    }
  };
};
/**
 * Maps the dispatch actions to the props for the App control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IAppProps) for the App control
 */
export const mapDispatchToConnectedAppProps = (
  _dispatch: Dispatch<{}>
): IAppActions => {
  return {};
};

/**
 * A redux connected App control.
 * @description Supports translation
 */
export const ConnectedApp = translate('MyAccess')(
  connect(
    mapStateToConnectedAppProps,
    mapDispatchToConnectedAppProps
  )(App)
);
